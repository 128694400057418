import React from "react";

export function getCommentContent(body) {
    let contentText;
    try {
        let content = body.content[0];
        while (content.type !== 'paragraph') {
            content = content.content[0];
        }
        contentText = content.content[0].text
        return <span style={{color: "black"}}>{contentText}</span>
    } catch (e) {
    }

    return null;
}