import React from 'react';
import './Paywall.css';
import { Modal } from '@material-ui/core';
import { StripeConnector } from '../../connectors/stripe/StripeConnector';
import PricingPlan from '../pricing/PricingPlan';

const stripeConnector = new StripeConnector();

export default function Paywall(props) {
    const handleLogout = async () => {
        await props.logout();
        props.openHome();
    };

    const logoutAndOpenLogin = async () => {
        await props.logout();
        props.openLogin();
    };

    return (
        <Modal
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            open={true}
            aria-labelledby="paywall-modal-title"
            aria-describedby="paywall-modal-description"
        >
            <div className="paywall-container">
                <h2 className="paywall-header">
                    No Active Subscription
                </h2>
                <p className="paywall-subheader">
                    It looks like you don’t have an active subscription at the moment. Choose a plan below to get started.
                </p>
                <PricingPlan
                    actionOne={() => {
                        stripeConnector
                            .createPaymentSession(
                                {
                                    organisationId: props.user.userData.organisations[0],
                                    userEmail: props.user.userEmail,
                                },
                                true
                            )
                            .then();
                    }}
                />
                <div className="paywall-actions">
                    <button className="paywall-button" onClick={logoutAndOpenLogin}>
                        Login with another account
                    </button>
                    <button className="paywall-button secondary" onClick={handleLogout}>
                        Logout
                    </button>
                </div>
            </div>
        </Modal>
    );
}
