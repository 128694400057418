import React from "react";
import {createLegalUrl} from "../LegalUtils";
import '../Legal.css';
import {Text} from "../../text/Text";

export function PrivacyPage(props) {
    const policyId = 15055526;
    const policyTypes = 'privacy';

    return (
        <div className='legalContentContainer'>
            {props.mobile ?
                <div style={{overflow: "auto", height: '100%', alignItems:"start", margin: 70, display: "flex", flexDirection: "column"}}>
                    <Text marginBottom={10} bold>
                        Privacy Policy for xlens
                    </Text>

                    <Text marginBottom={20} bold>
                        Last updated: 01.05.2024 16:19
                    </Text>

                    <Text marginBottom={40} italic>
                        At Prepublic Development Ltd, we are committed to maintaining the trust and confidence of all visitors to our
                        mobile application xlens. In particular, we want you to know that xlens is not in the business
                        of selling, renting, or trading email lists or any personal data with other companies and
                        businesses for marketing purposes.

                        This Privacy Policy provides detailed information on when and why we might need to access your
                        information (only through login credentials), how we use it, the limited conditions under which
                        we may disclose it to others, and how we keep it secure.
                    </Text>
                    <Text marginBottom={10} italic>
                        1. General Information

                        This privacy policy applies to xlens operated by Prepublic Development Ltd. It does not extend to any websites
                        or links that can be accessed from the app but are created and maintained by other organizations
                        or services that our app links to. Those sites are not governed by this Privacy Policy, but by
                        their own privacy policies. We encourage our users to read the privacy statements of other
                        websites before submitting any personal information.</Text>
                    <Text marginBottom={10} italic>
                        2. Information We Access

                        Our mobile application xlens does not collect or store any personal information. The app
                        requires users to log in with credentials managed and authenticated via AWS Cognito, a secure
                        third-party service provided by Amazon Web Services. By using AWS Cognito, we share your login
                        credentials with Amazon Web Services solely for the purpose of authentication. Please refer to
                        the AWS privacy policy for more information on how they manage data security.</Text>

                    <Text marginBottom={10} italic>
                        3. How We Use Your Information

                        Since xlens does not collect personal information, there are no uses of such data within the app
                        itself. Login credentials are used solely to grant access to the app’s functionalities and
                        verify user identity as registered and managed via AWS Cognito.</Text>

                    <Text marginBottom={10} italic>
                        4. Third Parties

                        We use AWS Cognito to manage the authentication process. This third-party service is the only
                        party with which we share information, specifically your login credentials, for the purpose of
                        securing and authenticating your access to our application.</Text>

                    <Text marginBottom={10} italic>
                        5. Data Security

                        Protecting your information is important to us. While our app does not store personal data, we
                        ensure that the authentication processes involving AWS Cognito are secure and protected against
                        unauthorized access and breaches. AWS Cognito adheres to high standards of security for data
                        protection.</Text>

                    <Text marginBottom={10} italic>
                        6. Changes to This Privacy Policy

                        We may update this privacy policy from time to time in response to changing legal, technical, or
                        business developments. When we update our privacy policy, we will take appropriate measures to
                        inform you, consistent with the significance of the changes we make. You can see when this
                        Privacy Policy was last updated by checking the date at the top of this page.</Text>

                    <Text marginBottom={40} italic>
                        7. Contact Us

                        If you have any questions about this privacy policy or the app itself, please do not hesitate to
                        contact us through:

                        Email: contact@xlens.io</Text>

                    <Text marginBottom={10} bold> By using our app, you agree to the terms laid out in this privacy policy.</Text>
                </div>
                : <iframe name="legalContent" src={createLegalUrl(policyId, policyTypes)}
                          style={{minHeight: 700, minWidth: 700, overflow: "auto", width: '100%', height: '100%'}}
                          frameBorder="0"/>}
        </div>
    )
}