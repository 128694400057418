import React, { useState, useRef, useEffect } from "react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import "./VideoPlayer.css";

const VideoPlayer = ({ videoId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const videoRef = useRef(null);

  const handleOpen = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleClose = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  const handleEscape = (event) => {
    if (event.key === "Escape") {
      handleClose();
    }
  };

  useEffect(() => {
    if (isOpen && videoRef.current) {
      videoRef.current.play();
    }
  }, [isOpen]);

  return (
      <>
        <div className="videoButtonContainer" onClick={handleOpen}>
          <button className="videoButton">
            <PlayArrowIcon />
          </button>
          <span className="videoButtonText">Xlens explained in 30 seconds</span>
        </div>
        {isOpen && (
            <div
                className="videoModalOverlay"
                onClick={handleClose}
                onKeyDown={handleEscape}
                tabIndex={-1}
            >
              <button className="closeButton" onClick={handleClose}>
                X
              </button>
              <div className="videoModalContent" onClick={(event) => event.stopPropagation()}>
                <video
                    ref={videoRef}
                    src="/video/meetXlens.mp4"
                    controls
                    autoPlay
                    className="videoPlayer"
                />
              </div>
            </div>
        )}
      </>
  );
};

export default VideoPlayer;
