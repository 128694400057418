export const ENTRY_FUNCTION_URL = 'https://wwa0eiyyr9.execute-api.eu-central-1.amazonaws.com/live/taskchain-entry-function'
export const BASE_URL_TEST = 'http://localhost:3000'
export const BASE_URL_LIVE = 'https://api.xlens.io'
export const BASE_URL = BASE_URL_LIVE;


export const ORDER_BY_PRIORITY = 'by_priority';
export const MONTHLY_PREMIUM_PLAN_PRODUCTION_PRICE_ID = 'price_1QERwxDpbTNEn0zwIchPUrrq';
export const YEARLY_PREMIUM_PLAN_PRODUCTION_PRICE_ID = 'price_1OZ6pyDpbTNEn0zwQOUqQ1nE';
export const YEARLY_PREMIUM_PLAN_PRODUCTION_PRICE_ID_TEST = 'price_1Ob27oDpbTNEn0zwfTdkXgnm';

export const UNKNOWN_USERNAME = 'unknown_user'

export const LAYOUT_BREAKPOINT_PX = 700;

export const MAXIMUM_CONNECTED_PROJECTS = 10;

export const CONSENT_COOKIE_NAME = "xlens_consent";
export const GA4_UNIQUE_TRACKING_ID = "G-9ELGP2SRLS";