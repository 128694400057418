import React from 'react';
import './SingleBlogPost.css'
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const SingleBlogPost = ({post, closeBlogPost}) => {
    return (
        <article className="blog-post">

            {/*<ArrowBackIcon className="back-button" onClick={closeBlogPost}/>*/}
            <div className="back-button" onClick={closeBlogPost}>&#x2190;</div>
            {post.images && post.images.length > 0 && (
                <img src={post.images[0]} alt={`Cover for ${post.title}`}/>
            )}
            <div className="blog-content">
                <h1>{post.title}</h1>
                <div className="blog-meta">
                    <span>By {post.author.name}</span> |
                    <span> Published on {post.datePublished}</span>
                </div>
                <div className="blog-tags">
                    <strong>Tags:</strong> {post.tags.join(', ')}
                </div>
                <section className="blog-section-container">
                    <p>{post.content.introduction}</p>
                    {post.content.sections.map((section, index) => (
                        <div className="blog-section" key={index}>
                            <h2>{section.heading}</h2>
                            <p>{section.text}</p>
                        </div>
                    ))}
                    <div className="blog-conclusion">
                        <h2 >Conclusion</h2>
                        <p>{post.content.conclusion}</p>
                    </div>
                </section>

                {post.socialMediaLinks ?
                    <footer className="social-links">
                        {post.socialMediaLinks.facebook ? <a href={post.socialMediaLinks.facebook} target="_blank"
                                                             rel="noopener noreferrer">Facebook</a> : null}
                        {post.socialMediaLinks.twitter ? <a href={post.socialMediaLinks.twitter} target="_blank"
                                                            rel="noopener noreferrer">Twitter</a> : null}
                        {post.socialMediaLinks.linkedin ? <a href={post.socialMediaLinks.linkedin} target="_blank"
                                                             rel="noopener noreferrer">LinkedIn</a> : null}
                    </footer> : null}
            </div>
        </article>
    );
};

export default SingleBlogPost;
