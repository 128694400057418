import {ReactComponent as Jira} from "../images/logo_jira.svg";
import {ReactComponent as Trello} from "../images/logo_trello.svg";
import React from "react";
import {Text} from "../components/text/Text";
import taskchain from "../images/taskchain_logo.png";

export function generateProjectKey(projectName) {
    let key;

    if (projectName) {
        if (projectName.includes(" ")) {
            let nameParts = projectName.split(" ");
            if (nameParts && Array.isArray(nameParts)) {
                for (let namePart of nameParts) {
                    let firstChar = namePart.charAt(0).toUpperCase();
                    key = key ? `${key}${firstChar}` : `${firstChar}`;
                }
            }
        } else {
            key = projectName.charAt(0).toUpperCase();
        }
    }
    return key;
}

export function getBadgeLogo(platform) {
    switch (platform) {
        case 'jira':
            return <Jira style={{opacity: 0.5, marginLeft: 20, width: 40, position: 'absolute', top: 5, left: 0}}/>
        case 'trello':
            return <Trello style={{opacity: 0.5, marginLeft: 20, width: 50, position: 'absolute', top: 5, left: 0}}/>
        case 'taskchain':
            return <img src={taskchain} style={{marginTop: -2, marginLeft: 17, position: "absolute", left: 0, top: 0, opacity:0.5, height: 50}} />
    }
}

export function areIssuesEqual(issueOne, issueTwo) {
    if (issueOne && issueTwo) {
        return issueOne.fields.project.id === issueTwo.fields.project.id && issueOne.key === issueTwo.key;
    }

    return false
}

export function areIssuesDifferent(issueOne, issueTwo) {
    if (issueOne && issueTwo) {
        return (issueOne.fields.project.id !== issueTwo.fields.project.id || issueOne.key !== issueTwo.key)
    }
    return false
}

export function getIssueKeyForPlatform(issue, platform) {
    switch (platform) {
        case 'jira': return issue.key
        case 'trello': return issue.id
    }
}
