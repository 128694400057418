import React from 'react'
import {ReactComponent as Jira} from '../../../images/logo_jira.svg'
import {ReactComponent as Trello} from '../../../images/logo_trello.svg'
import Taskchain from '../../../images/taskchain_logo.png'
import {Text} from "../../text/Text";

export default function LogoBadge(props) {
    let component;
    switch (props.platform) {
        case "jira":
            component = <Jira style={{marginLeft: -2, width: '50%', height: '70%', alignSelf:'center'}}/>;
            break;
        case "taskchain":
            component = <img style={{height: 30, color:'white', alignSelf:'center'}} src={Taskchain} alt={'taskchain'}/>
            break;
        case "trello":
            component = <Trello style={{marginLeft: 2, width: '50%', height: '70%', alignSelf:'center'}}/>;
            break;
    }
    return <div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-between', backgroundColor: "black", opacity: 0.6, height: 15, width: '100%'}}>
        {component}
        <Text marginRight={2} marginTop={2} alignSelf='center' light fontSize={7}>{props.displayName}</Text>
    </div>
};