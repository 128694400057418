import React from "react";
//margin: 0;
//     color: whitesmoke;
//     line-height: 80px;
//     font-weight: bold;
//     font-size: 72px;
//     width: 70%;
//     align-self: center;
//     text-align: center;
export function HeadlineText(props) {
    let screenWidth = window.innerWidth;
    let screenHeight = window.innerHeight;

    function getFontSize(){
        let fontSize;
        if (screenWidth > 1200) {
            fontSize = 72
        }else if (screenWidth > 1100) {
            fontSize = 68
        }else if (screenWidth > 1000) {
            fontSize = 64
        }else if (screenWidth > 900) {
            fontSize = 60
        }else if (screenWidth > 800) {
            fontSize = 56
        }else if (screenWidth > 700) {
            fontSize = 52
        }else if (screenWidth > 600) {
            fontSize = 48
        }else if (screenWidth > 500) {
            fontSize = 44
        }else if (screenWidth > 400) {
            fontSize = 40
        }

        return fontSize;
    }

    function getLineHeight(){
        let fontSize;
    }

    return <span style={{
        color: 'var(--hover-color)',
        textShadow: "1px 1px 2px black",
        opacity: 0.8,
        margin: 0,
        fontWeight: 'bold',
        fontSize: 65,
        width: '70%',
        alignSelf: 'center',
        textAlign: 'center'
    }}>{props.children}</span>;
}