import CookieConsent from "react-cookie-consent";
import {CONSENT_COOKIE_NAME} from "../../Constants";
import TextButton from "../button/TextButton";
import React from "react";
import {initTracking} from "../../util/cookieUtils";

export const CookieComponent = (props) => {
    return <CookieConsent
        cookieName={CONSENT_COOKIE_NAME}
        buttonText="I understand"
        declineButtonText="No thanks"
        style={{background: "#082032", borderColor:'#ffffffa8', borderStyle: 'solid', borderWidth: 1}}
        buttonStyle={{fontSize: "14px", borderRadius: 5, background:'white', color:'black'}}
        declineButtonStyle={{fontSize: "14px", borderRadius: 5, background:'transparent', opacity: 0.5, color:'white'}}
        enableDeclineButton
        onDecline={() => {
        }}
        onAccept={() => {
            initTracking()
        }}
    ><span style={{fontSize:14, opacity:0.5}}>We and selected third parties use cookies or similar technologies for technical purposes and, with your consent, for other purposes as specified in the </span> <TextButton onClick={() => {
        props.openCookiePolicy()
    }} opacity={0.9} fontSize={14}>Cookie Policy</TextButton></CookieConsent>
}