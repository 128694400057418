import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {JiraConnector} from "../connectors/jira/JiraConnector";
import React from "react";
import {TaskchainConnector} from "../connectors/taskchain/TaskchainConnector";
import {GeneralConnector} from "../connectors/general/GeneralConnector";

const prefix = 'blogs';
const generalConnector = new GeneralConnector();

export const fetchBlogposts = createAsyncThunk(`${prefix}/fetchBlogs`, async (dataObject, thunkAPI) => {
    let data = await generalConnector.fetchBlogposts(dataObject);

    return {
        blogpostSelection: data
    };
});

export const fetchSingleBlogpost = createAsyncThunk(`${prefix}/fetchBlogs`, async (blogId, thunkAPI) => {
    let data = await generalConnector.fetchSingleBlogpost(blogId);

    return {
        blogpostSelection: data
    };
});

const blogSlice = createSlice({
    name: prefix,
    initialState: {
       blogpostSelection:[],
       allBlogs:[]
    },
    extraReducers: {
        [fetchBlogposts.fulfilled]: (state, action) => {
            state.blogpostSelection = action.payload.blogpostSelection
        },
        [fetchBlogposts.rejected]: (state, action) => {
            //todo handle
        },

    }
});

export const blogReducer = blogSlice.reducer;
