import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Popover from "@material-ui/core/Popover";
import ClearIcon from "@material-ui/icons/Clear";
import BlockIcon from "@material-ui/icons/Block";
import ArchiveIcon from "@material-ui/icons/Archive";
import AddIcon from "@material-ui/icons/Add";
import {CheckCircleOutline} from "@material-ui/icons";
import {ReactComponent as Menu} from '../../images/ic_project_menu.svg'
import {IconButton, Tooltip} from "@material-ui/core";
import LogoBadge from "../badges/logobadge/LogoBadge";


const useStyles = makeStyles({
    root: {
        opacity: 0.3,
        width: 150,
        minWidth: 170,
        borderRadius: 3,
        '&:hover': {
            opacity: 1,
        }
    },
    rootSelected: {
        opacity: 1,
        width: 150,
        minWidth: 170,
        borderRadius: 3
    },
    media: {
        opacity: 1,
        minWidth: 150,
        height: 170,
    },
    mediaSelectedEnabled: {
        opacity: 1,
        minWidth: 150,
        height: 170,
    },
    mediaDisabled: {
        height: 170,
        minWidth: 150,
        opacity: 0.2
    },
});

export default function ProjectHolder(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const open = Boolean(anchorEl);

    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event) => {
        if (props.hasMenu) {
            setAnchorEl(event.currentTarget);
            if (typeof props.setMenuOpen == "function") {
                props.setMenuOpen(true);
            }
        }
    };

    const handleClose = () => {
        if (props.hasMenu) {
            setAnchorEl(null);
            if (typeof props.setMenuOpen == "function") {
                props.setMenuOpen(false);
            }
        }
    };

    const getMenu = () => {
        if(props.disableAdminMode) return null;

        if (props.disabled) {
            return <Tooltip title="enable project">
                <IconButton style={{padding: 0}}>
                    <CheckCircleOutline onClick={() => {
                        props.enableProject()
                    }} className='singleAction'/>
                </IconButton>
            </Tooltip>

        } else {
            return <Tooltip title="disable project">
                <IconButton style={{padding: 0}}>
                    <BlockIcon onClick={() => {
                        props.disableProject()
                    }} className='singleAction'/>
                </IconButton>
            </Tooltip>
        }
    };

    function getMediaStyle() {
        if (props.selected) {
            return classes.mediaSelectedEnabled
        } else {
            return classes.media
        }
    }

    let mediaStyle = props.disabled ? classes.mediaDisabled : getMediaStyle();
    let backgroundStyle = props.color ? {backgroundColor: props.color, alignSelf: 'center'} : {alignSelf: 'center'};

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContents: 'start'
        }}>
            {props.hasMenu && props.mainUser && props.mainUser.userData && props.mainUser.userData.accessLevel === "admin" ? <Tooltip title="open project menu">
                <IconButton style={{alignSelf: "start", padding: 0}}>
                    <Menu onClick={handleClick} style={{opacity: 0.3, width: 20}}/>
                </IconButton>
            </Tooltip> : null}
            <Popover
                style={{marginLeft: -20, marginTop: 20}}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div style={{backgroundColor: "white"}}>
                    <Tooltip title="remove project">
                        <IconButton style={{padding: 0}}>
                            <ClearIcon onClick={async () => {
                                if (props.disableAdminMode) {
                                    //todo unify naming of the email property. currently "userEmail" is used for the main user and "email" for the other users
                                    props.removeProjectFromUser({
                                        email: props.user.userEmail ? props.user.userEmail : props.user.email,
                                        organisationId: props.mainUser.userData.organisations[0], //TODO handle multiple organisations
                                        projectId: props.projectId
                                    })
                                } else {
                                    props.removeProject();
                                }
                            }} className='singleAction'/>
                        </IconButton>
                    </Tooltip>
                    {getMenu()}
                </div>
            </Popover>

            <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContents: 'start'
            }}>
                <Card className={props.selected ? classes.rootSelected : classes.root}>
                    {props.image ? <CardMedia
                        onClick={() => {
                            if (props.selectProject) {
                                let meta = {};

                                if (props.connectionId) meta.connectionId = props.connectionId
                                props.selectProject(props.projectId, meta);
                            }
                        }}
                        className={mediaStyle}
                        image={props.image}
                        title={props.projectId}
                    >
                        <LogoBadge displayName={props.displayName} projectKey={props.projectKey}
                                   platform={props.platform}/>
                    </CardMedia> : <div onClick={() => {
                        if (props.selectProject) {
                            let meta = {};

                            if (props.connectionId) meta.connectionId = props.connectionId

                            props.selectProject(props.projectId, meta);
                        }
                    }} style={backgroundStyle} className={mediaStyle}>
                        <LogoBadge displayName={props.displayName} projectKey={props.projectKey} platform={props.platform}/>
                    </div>}
                </Card>
            </div>
        </div>

    );
}
