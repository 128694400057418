import {ENTRY_FUNCTION_URL, BASE_URL} from "../../Constants";

export class JiraConnector {

    async setTokenAndFetchProjects(dataObject) {
        let response = await fetch(`${BASE_URL}/jira/obtain-access-token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataObject)
        });
        console.log(
            `Response getToken: ${response.status} ${response.statusText}`
        );

        let projectsData = {}
        try {
            if (response) {
                let body = await response.json();
                if (body) {
                    projectsData = body.data
                }
            }
        } catch (e) {
            console.error(e)
        }
        return projectsData
    }

    async addProjects(dataObject) {
        let data = {
            organisationId: dataObject.organisationId,
            connectionId: dataObject.connectionId,
            userEmail: dataObject.userEmail,
            projectsData: dataObject.selectedProjects,
        }

        let response = await fetch(`${BASE_URL}/jira/add-projects`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        console.log(
            `Response addProjects: ${response.status} ${response.statusText}`
        );

        let projects = []
        if (response) {
            let data = await response.json();
            if (data) {
                projects = data.data
            }
        }

        return projects
    }

    async fetchProjectData(dataObject) {
        let data = {
            userId: dataObject.userId,
            organisationId: dataObject.organisationId
        }

        let response = await fetch(`${BASE_URL}/jira/fetch-projects`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        console.log(
            `Response getToken: ${response.status} ${response.statusText}`
        );

        let projectsData = {}
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body);
            if (body) {
                projectsData = body.data.projects
            }
        }
        return projectsData
    }

    async fetchUntrackedProjectData(username) {
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username,
                platform: 'jira',
                event: {
                    type: 'fetch_untracked_projects'
                }
            })
        });


        let projectsData = []
        if (response) {
            let data = await response.json();

            let body = JSON.parse(data.body)
            if (body) {
                projectsData = body.data
            }
        }
        return projectsData
    }

    async connectAccount(username, data) {
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username,
                platform: "jira",
                event: {
                    type: "add_account_connection",
                    data: data
                }
            })
        });

        let projectsData = {}
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body)
            if (body) {
                projectsData = body.data.projects
            }
        }
        return projectsData
    }

    async disableProject(dataObject) {
        let requestData = {
            username: dataObject.username,
            platform: dataObject.platform,
            event: {
                type: 'disable_project',
                data: {
                    connectionId: dataObject.connectionId,
                    projectId: dataObject.projectId
                }
            }
        }

        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let data = await response.json();
        let body = JSON.parse(data.body)
        if (body) {
            return body.data.disabledProjectId
        }
        return [];
    }

    async enableProject(dataObject) {
        let requestData = {
            username: dataObject.username,
            platform: dataObject.platform,
            event: {
                type: 'enable_project',
                data: {
                    connectionId: dataObject.connectionId,
                    projectId: dataObject.projectId
                }
            }
        }

        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let data = await response.json();
        let body = JSON.parse(data.body)
        if (body) {
            return body.data.enabledProjectId
        }
        return [];
    }

    async removeProject(dataObject) {
        let requestData = {
            username: dataObject.username,
            platform: dataObject.platform,
            event: {
                type: 'remove_project',
                data: {
                    connectionId: dataObject.connectionId,
                    projectKey: dataObject.projectKey,
                    projectId: dataObject.projectId
                }
            }
        }

        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let data = await response.json();
        let body = JSON.parse(data.body)
        if (body) {
            return body.data
        }
        return [];
    }

    async fetchUsers(dataObject) {
        let requestData = {
            username: dataObject.username,
            platform: 'jira',
            event: {
                type: 'fetch_users',
                data: {
                    domain: dataObject.domain,
                    projectKey: dataObject.projectKey
                }
            }
        }

        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let data = await response.json();
        let body = JSON.parse(data.body)
        if (body) {
            return body.data
        }
        return null;
    }

    async changeAssignee(dataObject) {
        let requestData = {
            username: dataObject.username,
            platform: 'jira',
            event: {
                type: 'change_assignee',
                data: {
                    domain: dataObject.domain,
                    issueKey: dataObject.issueKey,
                    accountId: dataObject.accountId
                }
            }
        }

        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let data = await response.json();
        let body = JSON.parse(data.body)
        if (body) {
            return body.data
        }
        return null;
    }

    async fetchNotConnectedProjects(dataObject) {
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: dataObject.username,
                platform: 'jira',
                event: {
                    domain: dataObject.domain,
                    type: 'fetch_untracked_projects'
                }
            })
        });

        let projectsData = []
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body)
            if (body) {
                projectsData = body.data
            }
        }
        return projectsData
    }

    async fetchComments(dataObject) {
        let requestData = {
            username: dataObject.username,
            platform: dataObject.platform,
            event: {
                type: 'fetch_comments',
                data: {
                    domain: dataObject.domain,
                    issueKey: dataObject.issueKey
                }
            }
        }

        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let data = await response.json();
        let body = JSON.parse(data.body)
        if (body) {
            return body.data.comments
        }
        return [];
    }
}

export const generateAuthCodeUrl = (dataObject) => {
    switch (dataObject.platform) {
        case 'jira': {
            return `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=CJOJsl25iimpp5A4nyZeIHtdooQXikCP&scope=write%3Ajira-work%20read%3Ajira-work%20offline_access%20read%3Ajira-user&redirect_uri=https%3A%2F%2Ftaskchain.io%2Fapp%2Fauthentication-jira&state=${JSON.stringify(dataObject)}&response_type=code&prompt=consent`
            // return `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=CJOJsl25iimpp5A4nyZeIHtdooQXikCP&scope=write%3Ajira-work%20read%3Ajira-work%20offline_access%20read%3Ajira-user&redirect_uri=http%3A%2F%2Flocalhost%3A3001%2Fapp&state=${JSON.stringify(dataObject)}&response_type=code&prompt=consent`
        }
        case 'trello': {
            return 'https://trello.com/1/authorize?expiration=never&name=taskchain&scope=read,write&response_type=token&key=c405b2ae7f72e644a683c8ebac26bac3&return_url=https://taskchain.io/app/authentication-trello&callback_method=fragment'
            // return 'https://trello.com/1/authorize?expiration=never&name=taskchain&scope=read&response_type=token&key=c405b2ae7f72e644a683c8ebac26bac3&return_url=http://localhost:3000/app/authentication-trello&callback_method=fragment'
        }
    }
}

