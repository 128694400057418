import TextWithImage from "../../../components/textWithImage/TextWithImage";
import React from "react";
import {Text} from "../../../components/text/Text";
import {SupportedPlatforms} from "../../../components/supported/SupportedPlattforms";
import XlensIntroGif from "../../../images/xlens_intro.gif";
import FeatureImageChat from "../../../images/screenshot_chat.png";
import FeatureAnnotations from "../../../images/screenshot_annotations.png";
import FeatureMobile from "../../../images/screenshot_mobile_bug_reporting.png";
import FeatureIntegrations from "../../../images/screenshot_integrations.png";
import FeatureBugReporting from "../../../images/jira_issue_extension.png";
import '../HomePage.css';
import VideoPlayer from "../../../components/videoPlayer/VideoPlayer";

export const IntroductionPage = (props) => {

    return (
        <>
            <div className={'introduction-page-container'}>
                <div className={'introduction-page-header-container'}>
                    <div className={'introduction-header'}>
                        <div className={'introduction-text-header'}> Upgrade your <span
                            className={'introduction-chip-header'}>QA</span> team
                        </div>
                        <span className={'introduction-header-info-text'}>Effortless
                        feedback and bug reporting</span>
                        <button
                            className={'get-started-button'}
                            onClick={() => {
                                props.openRegistration()
                            }}>Get Started
                        </button>
                    </div>
                </div>

                <VideoPlayer videoId='COYd7hPZnIA'/>

                <div className={'introduction-platforms'}>
                    <img className={'xlens-intro-gif'} id="xlens-main-video" src={XlensIntroGif} width="80%" alt=""/>

                    <SupportedPlatforms/>
                    <Text bold fontSize={42} color="black"> Simplify feedback. Elevate
                        productivity.</Text>
                </div>

                <div className={'introduction-page-body-container'}>
                    <div className={'body-container-first'}>
                        <TextWithImage
                            chipLabel="COLLABORATION"
                            chipColor="var(--feature-badge)"
                            textHeader="Collaborate and Communicate"
                            text="Collaborate and Communicate with team members working on the website. Smooth collaboration made by clear bug reports for better issue understanding and quick solutions."
                            imageSrc={FeatureImageChat}
                            imageAlt="Collaboration"
                            align="left"
                        />
                        <TextWithImage
                            chipLabel="BUG REPORTING"
                            chipColor="var(--feature-badge)"
                            textHeader="Visual Bug Tracking"
                            text="Recognising and documenting bugs, through reports, screenshots, or detailed descriptions of the problem."
                            imageSrc={FeatureBugReporting}
                            imageAlt="Bug Reporting"
                            align="right"
                        />
                    </div>
                    <div className={'body-container-second'}>
                        <TextWithImage
                            chipLabel="ANDROID & IOS"
                            chipColor="var(--feature-badge)"
                            textHeader="Mobile Bug Reporting"
                            text="Ensure compatibility with mobile devices, allowing users to capture and report bugs or provide feedback even when working on smartphones or tablets."
                            imageSrc={FeatureMobile}
                            imageAlt="Mobile"
                            align="left"
                        />
                        <TextWithImage
                            chipLabel="ANNOTATIONS"
                            chipColor="var(--feature-badge)"
                            textHeader="Enhance understanding"
                            text="Explanations and Clarifications for future updates or changes made easy with our website annotations."
                            imageSrc={FeatureAnnotations}
                            imageAlt="COLLABORATION"
                            imageWidth="1000px"
                            imageHeight="1000px"
                            align="right"
                        />
                    </div>
                    <div className={'body-container-third'}>
                        <TextWithImage
                            chipLabel="INTEGRATIONS"
                            chipColor="var(--feature-badge)"
                            textHeader="Project Management Integration"
                            text="Integration with project management or development tools (such as Jira or Trello) allows seamless transfer of bug reports into the development workflow, ensuring no reported issue is overlooked."
                            imageSrc={FeatureIntegrations}
                            imageAlt="COLLABORATION"
                            imageWidth="1000px"
                            imageHeight="1000px"
                            align="left"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
