import React from "react";
import {Text} from "../text/Text";
import {ReactComponent as Logo} from '../../images/ic_chain_logo.svg'
import TextButton from "../button/TextButton";

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: screen.width,
        }
    }

    updateDimensions = () => {
        this.setState({width: screen.width});
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {

        //wtf
        let marginTop = 700;
        if (this.state.width < 450) {
            marginTop = 2500;
        }else if (this.state.width < 850) {
            marginTop = 1500;
        }

        return (
            <div style={{
                // marginTop: marginTop,
                flexShrink: 0,
                paddingTop: 40,
                paddingBottom: 40,
                justifyContent: 'start',
                display: 'flex',
                height: 400,
                maxHeight: 400,
                flexDirection: 'row',
                width: '100%',
                backgroundColor: '#061a28'
            }}>
                <div style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    width: 100,
                    marginLeft: 40,
                    marginRight: 40,
                    maxHeight: 100,
                    maxWidth: 300,
                    height: '100%'
                }}>
                    <Logo style={{opacity: 0.1, maxHeight: 70}}/>
                </div>
                <div style={{
                    marginRight: 40,
                    alignItems: 'start',
                    justifyContent: 'start',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Text alignSelf='start' light marginTop={20}>Product</Text>
                    <TextButton color="white" opacity={0.5} alignSelf='start' marginTop={15} light onClick={() => {
                        this.props.openCookiePolicy();
                    }}>Cookie Policy</TextButton>
                    <TextButton color="white"  opacity={0.5} alignSelf='start' marginTop={10} light onClick={() => {
                        this.props.openPrivacy();
                    }}>Privacy Policy</TextButton>
                    <TextButton color="white"  opacity={0.5} alignSelf='start' marginTop={10} light onClick={() => {
                        this.props.openTermsAndConditions();
                    }}>Terms of Service</TextButton>

                </div>
                <div style={{
                    marginRight: 40,
                    justifyContent: 'start',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Text alignSelf='start' light marginTop={20}>Support</Text>
                    <TextButton color="white"  opacity={0.5} alignSelf='start' marginTop={15} light onClick={() => {
                        return window.open('mailto:contact@xlens.io', '_blank').focus();
                    }}>Email Support</TextButton>
                </div>
            </div>
        )
    }
}