import React from 'react'
import {connect} from 'react-redux'
import Login from "../login/Login";
import {getCurrentUser, logout} from "../../reducers/userReducer";
import './Home.css'
import Chain from "../chain/Chain";
import {
    fetchProjects, setLoading,
    setTokenAndFetchProjects
} from "../../reducers/accountReducer";
import Header from "../../components/header/Header";
import {push, replace, goBack} from "connected-react-router";
import qs from "qs";
import {CookieComponent} from "../../components/cookiecomponent/CookieComponent";
import Users from "../users/Users";
import Invitations from "../invitations/Invitations";
import Paywall from "../../components/paywall/Paywall";

class Home extends React.Component {

    constructor() {
        super();
        this.state = {
            initialProjectFetchFinished: false
        }
    }

    maybeHandleAuthResponse(user) {
        try {
            if (window.location.pathname.includes('authentication-trello')) {
                let token = this.props.location.hash.substr(this.props.location.hash.indexOf('=') + 1);

                if (!token.includes('error') && !token.includes('rejected')) {
                    let state = {
                        platform: 'trello',
                        token: token,
                        username: user ? user.userEmail : ""
                    };

                    this.props.setTokenAndFetchProjects(state);
                    this.props.showApp()
                } else {
                    //TODO show error
                }
                return true;
            } else if (window.location.pathname.includes('authentication-jira')) {
                let params = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
                if (params && params.state) {
                    let code = params.code;
                    let state = JSON.parse(params.state);
                    if (state) {
                        this.props.setTokenAndFetchProjects({
                            authCode: code,
                            platform: state.platform,
                            platformEmail: state.platformEmail,
                            domain: state.domain,
                            connectionId: state.accountConnectionId,
                            username: user ? user.userEmail : ""
                        });
                        this.props.showApp()
                    }
                }
                return true
            }
        } catch (e) {
            console.error(e);
        }

        return false;
    }

    componentDidMount() {
        this.props.getCurrentUser()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.user) {
            if (!this.maybeHandleAuthResponse(nextProps.user) && !this.state.initialProjectFetchFinished) {
                this.props.fetchProjects(nextProps.user);
                this.setState({initialProjectFetchFinished: true})
            }
            this.props.stopLoading();
        }
    }

    getComponent() {
        if (this.props.showInvitations) {
            return <Invitations openApp={this.props.showApp} goBack={this.props.goBack}
                                invitationId={this.props.invitationId} goBackToHome={this.props.goBackToHome}
                                user={this.props.user}/>
        } else if (this.props.showUsers) {
            return <Users user={this.props.user}/>
        } else {
            return <Chain projects={this.props.projects} user={this.props.user}/>;
        }
    }

    render() {
        return (
            this.props.user ?
                <div className='homeMainContainer'>
                    {this.props.showPaywall ? <Paywall
                        user={this.props.user}
                        logout={this.props.logout}
                        openLogin={this.props.openLogin}
                        openHome={this.props.openHome}/> : null}
                    <Header/>
                    {this.getComponent()}
                    <CookieComponent openCookiePolicy={this.props.openCookiePolicy}/>
                </div> : <Login invitationId={this.props.invitationId}/>
        );
    }
}

const mapStateToProps = (state) => {
    let user = state.userReducer.currentUser;
    let showPaywall = false;
    if (user) {
        showPaywall = !user.subscriptionStatus?.isSubscribed;
    }
    return {
        projects: state.accountReducer.projects,
        showPaywall: showPaywall,
        user: state.userReducer.currentUser,
        newProjects: state.accountReducer.newProjects
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTokenAndFetchProjects: (dataObject) => dispatch(setTokenAndFetchProjects(dataObject)),
        logout: () => dispatch(logout()),
        openHome: () => dispatch(push('/')),
        goBackToHome: () => dispatch(replace('/')),
        showApp: () => dispatch(replace('/app')),
        openLogin: () => dispatch(push('/login')),
        goBack: () => dispatch(goBack()),
        fetchProjects: (user) => dispatch(fetchProjects(user)),
        getCurrentUser: () => dispatch(getCurrentUser()),
        stopLoading: () => dispatch(setLoading(false)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home)
