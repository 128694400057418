import React from 'react';
import {JiraConnector} from "../../connectors/jira/JiraConnector";
import './TaskInfo.css'
import {Modal, TextField} from "@material-ui/core";
import {getTestComments} from "../../containers/homepage/demo/data/TestData";
import {GeneralConnector} from "../../connectors/general/GeneralConnector";
import Avatar from "@material-ui/core/Avatar";
import {Send} from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import {getCommentContent} from "../../util/chat/chatUtils";


export default class TaskInfo extends React.Component {
    constructor() {
        super();
        this.state = {
            comments: [],
            newComment: ''
        }
        this.jiraConnector = new JiraConnector()
        this.generalConnector = new GeneralConnector()
    }

    componentDidMount() {
        if (!this.props.testMode) {
            this.fetchComments();
        }
    }

    async fetchComments() {
        if (this.props.user) {
            let organisationId = this.props.user.userData.organisations[0]
            let userEmail = this.props.user.userEmail;

            let data = await this.generalConnector.fetchComments(userEmail, organisationId, this.props.connectionId, this.props.originalProjectId, this.props.issueKey);

            this.setState({
                comments: data.comments.reverse()
            })
        }
    }

    async postComment() {
        if (this.props.user) {
            let organisationId = this.props.user.userData.organisations[0]
            let userEmail = this.props.user.userEmail;

            let data = await this.generalConnector.postComment(userEmail, organisationId, this.props.connectionId, this.props.originalProjectId, this.props.issueKey, this.state.newComment);

            let newComments = []
            if (data && data.comment) {
                newComments.push(data.comment)
                newComments = newComments.concat(this.state.comments);
            }
            this.setState({
                comments: newComments,
            });
        }
    }

    render() {
        const open = Boolean(this.props.issueKey);
        return (
            <Modal
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                open={open}
                onClose={() => this.handleClose()}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">

                <div className='mainContainer'>
                    <div className='titleContainer'>
                        {this.getTextWithAvatars(this.props.title, this.props.usersData)}
                    </div>
                    <div className='descriptionContainer'>
                        <div className='contentTitle'>
                            <span>Description:</span>
                        </div>
                        <div className='content'>
                            {this.getTextWithAvatars(this.props.description, this.props.usersData)}
                        </div>
                    </div>
                    <TextField
                        className='chatInputField'
                        autoFocus={true}
                        multiline={true}
                        maxRows={5}
                        placeholder="Enter comment"
                        onChange={(event) => {
                            this.setState({
                                newComment: event.target.value
                            })
                        }}
                        FormHelperTextProps={{
                            style: {
                                color: 'transparent'
                            }
                        }}
                        InputProps={{
                            style: {
                                padding: 20
                            },
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment style={{cursor: 'pointer', color: '', marginRight: 10, marginLeft: 3}}>
                                    <Send onClick={() => {
                                        this.setState({
                                            newComment: ''
                                        })
                                        this.postComment()
                                    }}/>
                                </InputAdornment>
                            )
                        }}/>
                    <div className='commentsContainer'>
                        <div className='contentTitle'>
                            <span style={{color: "black"}}>Communication:</span>
                        </div>
                        <div className='content'>
                            {this.state.comments.map(comment => {
                                let createDate = new Date(comment.created)
                                let dateTimeString = `${createDate.getDate()}.${createDate.getMonth() + 1} ${createDate.getFullYear()} ${createDate.getHours()}:${createDate.getMinutes()}`
                                //TODO TYPE-HANDLER handle other types than paragraph

                                let commentContent = getCommentContent(comment.body)

                                return <div style={{color: "black", display: 'flex'}}>
                                    <Avatar style={{marginRight: 5}} alt={comment.author.displayName}
                                            src={comment.author.avatarUrls['48x48']}/>
                                    <div className='singleComment'>
                                        <div className='singleCommentTitle'>
                                            <div style={{color: "black", display: 'flex'}}>
                                                <span style={{
                                                    color: "black",
                                                    marginLeft: 10,
                                                    alignSelf: 'center'
                                                }}>{`${comment.author.displayName}:`}</span>
                                            </div>
                                        </div>
                                        {commentContent}
                                        <span style={{
                                            textAlign: 'right',
                                            width: '100%',
                                            fontSize: 12,
                                            color: 'black'
                                        }}> {`${dateTimeString} `}</span>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    getTextWithAvatars(text, usersData) {
        //TODO add images
        let reference = "[~accountid:";
        let textParts = [];
        try {
            if (usersData) {
                let users = usersData.users;
                if (users && users.length > 0) {
                    while (text.includes(reference)) {
                        let index = text.indexOf(reference);
                        let postIndexText = text.substring(index + reference.length, text.length); //
                        let lastIndexOfAccountReference = postIndexText.indexOf(']');
                        let accountId = postIndexText.substring(0, lastIndexOfAccountReference);

                        let user = users.find(user => user.accountId === accountId);
                        if (user) {
                            let userDisplayName = user.displayName
                            text = text.replace(`${reference}${accountId}]`, `${userDisplayName}`);
                        }
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }


        return <span>{text}</span>
    }

    handleClose() {
        this.props.closeInfobox()
    }
}
