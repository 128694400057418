import LogoImg from "../../../../images/logo.svg";
import React from "react";
import { Link } from 'react-router-dom';
import "./Logo.css";

export const Logo = (props) => {
  return (
    <Link to="/">
      <img src={LogoImg} alt={``} className="logo" style={{width: props.width ? props.width : "100%"}} />
    </Link>
  );
};
