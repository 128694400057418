// BlogPostCard.js
import React from 'react';
import './BlogPostCard.css';  // Assuming styles are placed here

const BlogPostCard = ({ post, openBlogPost }) => {
    // Simple formatting for the date
    const formatDate = date => {
        return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    };

    return (<article onClick={() => {
            if (openBlogPost) {
                openBlogPost(post)
            }
        }} className="blog-card">
            {post.images && post.images.length > 0 && (
                <img src={post.images[0]} alt={post.title} className="cover-image" />
            )}
            <h2 className="title">{post.title}</h2>
            <div className="metadata">
                <span className="author">By {post.author.name}</span>
                <span className="date-published">· {formatDate(post.datePublished)}</span>
            </div>
            <p className="category">Category: {post.category}</p>
            <div className="tags">
                {post.tags.map(tag => <span key={tag} className="tag">{tag}</span>)}
            </div>
            <p className="introduction">{post.content.introduction}</p>
            {post.content.sections.slice(0,1).map(section => (
                <div key={section.heading} className="section">
                    <h4>{section.heading}</h4>
                    <p>{section.text}</p>
                </div>
            ))}
            <p className="conclusion">{post.content.conclusion}</p>
            {/*<div className="social-links">*/}
            {/*    <a href={post.socialMediaLinks.facebook} target="_blank" rel="noopener noreferrer">Facebook</a>*/}
            {/*    <a href={post.socialMediaLinks.twitter} target="_blank" rel="noopener noreferrer">Twitter</a>*/}
            {/*    <a href={post.socialMediaLinks.linkedin} target="_blank" rel="noopener noreferrer">LinkedIn</a>*/}
            {/*</div>*/}
        </article>
    )
};

export default BlogPostCard;
