import React from "react";
import './PricingPlan.css';
import BadgeWrapper from "../badgewrapper/BadgeWrapper";

export default function PricingPlan(props) {
    return (
        <div className="pricing-plan-container">
            <BadgeWrapper text="Beta">
                <div className="pricing-plan-card" onClick={props.actionOne}>
                    <div className="pricing-header">
                        <h3>30-Day Free Trial</h3>
                        <p>Then enjoy premium features</p>
                    </div>
                    <div className="pricing-details">
                        <div className="pricing-amount">
                            <span className="currency">€</span>
                            <span className="amount">49</span>
                            <span className="frequency">/month</span>
                        </div>
                    </div>
                    <ul className="features-list">
                        <li>Access for Up to 5 Users</li>
                        <li>Unlimited Feedback Reports</li>
                        <li>Advanced Collaboration Tools</li>
                        <li>Screenshots & Annotations</li>
                        <li>All Current & Future Integrations</li>
                    </ul>
                    <button className="cta-button">Start Free Trial</button>
                </div>
            </BadgeWrapper>
        </div>
    );
}
