import {Button} from "@material-ui/core";
import React from "react";


export default function TextButton(props){
    function getBorderWidth() {
        if (props.pressed) {
            return 2;
        }
        return 1;
    }

    let style = props.style ? props.style : {
        opacity: props.opacity ? props.opacity : 1,
        alignSelf: props.alignSelf ? props.alignSelf : 'center',
        fontSize: props.fontSize ? props.fontSize: 16,
        textTransform: 'none',
        padding: 0,
        paddingLeft:5,
        paddingRight:5,
        margin: 0,
        borderWidth: props.outlined ? getBorderWidth() : 0,
        borderColor: props.pressed ? '#FF4C29': 'white',
        borderStyle: 'solid',
        width: props.width ? props.width : 'auto',
        marginTop: props.marginTop ? props.marginTop : 0,
        marginRight: props.marginRight ? props.marginRight : 0,
        marginLeft: props.marginLeft ? props.marginLeft : 0,
        marginBottom: props.marginBottom ? props.marginBottom : 0,
        backgroundColor: props.selected ? 'white' : 'transparent',
        color: props.color ? props.color : 'black',
        borderRadius: 5,
    }
        return (
            <Button
                onClick={props.onClick}
                style={style}>{props.children}</Button>
        );
}

