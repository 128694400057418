import * as React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Text } from "../../text/Text";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { UNKNOWN_USERNAME } from "../../../Constants";
import { useState } from "react";
import { Typography } from "@material-ui/core";


export const useStyles = makeStyles(() => ({
  menuPaper: {
    backgroundColor: "#334756",
  },
}));

export default function HeaderMenu(props) {
  let classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  let email = props.user ? props.user.userEmail : UNKNOWN_USERNAME;
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    handleClose();
    await props.logout();
    props.openHomePage();
  };

  const handleOpenUsers = async () => {
    handleClose();
    props.openUsers();
  };

  const handleCancelSubscription = async () => {
    handleClose();
    props.cancelSubscription({ email });
  };

  const handleReactivateSubscription = async () => {
    handleClose();
    props.reactivateSubscription({ email });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let firstLetter = email ? email.charAt(0).toUpperCase() : "X";

  return (
    <div className="headerMenuContainer">
      <div
        onClick={handleClick}
        style={{
          marginTop: 10,
          width: 30,
          height: 30,
          borderRadius: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "var(--main-color)",
        }}
      >
        <Typography fontSize={18} light={true}>
          {firstLetter}
        </Typography>
      </div>
      <Menu
        classes={{ paper: classes.menuPaper }}
        style={{ marginTop: 25 }}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
          <MenuItem disabled style={{fontSize: 14, color: "white", opacity: 0.4, marginBottom: 5 }}>
              {email}
          </MenuItem>

        <MenuItem
          style={{display: `${props.user.userData.accessLevel !== "admin" ? "none" : ""}`, color: "white", opacity: 0.8, marginBottom: 5 }}
          onClick={handleOpenUsers}
        >
          Users
        </MenuItem>
        <MenuItem
          style={{ color: "white", opacity: 0.5 }}
          onClick={handleLogout}
        >
          Logout
        </MenuItem>

        {/*{props.user.subscriptionStatus &&*/}
        {/*props.user.subscriptionStatus.subscriptionData &&*/}
        {/*props.user.subscriptionStatus.subscriptionData.canceled ? (*/}
        {/*  <MenuItem*/}
        {/*    style={{ display: `${props.user.userData.accessLevel !== "admin" ? "none" : ""}`, color: "white", opacity: 0.5 }}*/}
        {/*    onClick={handleReactivateSubscription}*/}
        {/*  >*/}
        {/*    Reactivate subscription*/}
        {/*  </MenuItem>*/}
        {/*) : (*/}
        {/*  <MenuItem*/}
        {/*    style={{ display: `${props.user.userData.accessLevel !== "admin" ? "none" : ""}`, color: "white", opacity: 0.5 }}*/}
        {/*    onClick={handleCancelSubscription}*/}
        {/*  >*/}
        {/*    Cancel subscription*/}
        {/*  </MenuItem>*/}
        {/*)}*/}
      </Menu>
    </div>
  );
}
