import {Button} from "@material-ui/core";
import React, {useState} from "react";
import "./InviteButton.css";
import Select from "@material-ui/core/Select";

function InviteButton(props) {
    const [showInput, setShowInput] = useState(false);
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [selectedAccessLevel, setSelectedAccessLevel] = useState("user");

    const handleButtonClick = () => {
        setShowInput(!showInput);
        if (!showInput) {
            setEmail("");
        }
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailChange = (event) => {
        const inputValue = event.target.value;
        setEmail(inputValue);
        setIsValidEmail(validateEmail(inputValue));
    };

    return (
        <div>
            {!showInput ? (
                <button onClick={handleButtonClick} className='invite-button'>
                    Invite Members
                </button>
            ) : (
                <div className='invite-container'>
                    <input
                        className='invite-input'
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder={"Enter invitation email"}
                    />
                    <Select
                        native
                        className='access-level-dropdown'
                        value={selectedAccessLevel}
                        onChange={(event) => setSelectedAccessLevel(event.target.value)}
                        inputProps={{
                            name: 'status',
                            id: 'outlined-age-native-simple',
                        }}
                    >
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                    </Select>
                    <button
                        className='invite-button'
                        onClick={() => {
                            props.sendInvitation(email, selectedAccessLevel);
                            setShowInput(false);
                        }}
                        disabled={!isValidEmail}
                    >
                        Send Ivitation
                    </button>
                </div>
            )}
        </div>
    );
}

export default InviteButton;
