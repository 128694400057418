import {combineReducers} from 'redux'
import {userReducer} from "./userReducer";
import {accountReducer} from "./accountReducer";
import {taskReducer} from "./taskReducer";
import {blogReducer} from "./blogReducer";

export default combineReducers({
    userReducer,
    accountReducer,
    taskReducer,
    blogReducer
})
