import {connect} from "react-redux";
import {fetchBlogposts, fetchSingleBlogpost} from "../../reducers/blogReducer";
import  './Blog.css'
import React from "react";
import BlogPostCard from "../../components/blogpost/blogpostcard/BlogPostCard";
import SingleBlogPost from "../../components/blogpost/singleblogpost/SingleBlogPost";
import {replace} from "connected-react-router";
import {GeneralConnector} from "../../connectors/general/GeneralConnector";

class Blog extends React.Component {

    constructor() {
        super();
        this.generalConnector = new GeneralConnector()
        this.state = {
            showSingleBlogpost: null //contains the complete date of the post
        }
    }
    componentDidMount() {
        //TODO fetch only the last xy
        this.props.fetchBlogposts()

        if (this.props.blogId) {
            try {
                const [beforeLastHyphen, blogId] = [
                    this.props.blogId.substring(0, this.props.blogId.lastIndexOf('-')),
                    this.props.blogId.substring(this.props.blogId.lastIndexOf('-') + 1)
                ];
                this.fetchSingleBlogpost(blogId);
            } catch (e) {
                alert(e)
            }
        }
    }

    async fetchSingleBlogpost(blogId) {
        const blogpost = await this.generalConnector.fetchSingleBlogpost(blogId)
        this.setState({
            showSingleBlogpost: blogpost
        })
    }

    render() {
        return  <div className="grid-container">
            {this.state.showSingleBlogpost ? <div>
                <SingleBlogPost closeBlogPost={() => {
                    this.props.changeUrl("/blog")
                    this.setState({
                        showSingleBlogpost: null
                    })

                }} post={this.state.showSingleBlogpost}/>
            </div>: this.props.blogpostSelection.map(post => (
                <BlogPostCard openBlogPost={(post) => {
                    this.setState({
                        showSingleBlogpost: post
                    })
                    this.props.changeUrl(`/blog/${post.slug}-${post._id}`)
                }} post={post}/>
            ))}
        </div>
    }
}

const mapStateToProps = (state) => {

    return {
        blogpostSelection: [...state.blogReducer.blogpostSelection].reverse()
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchSingleBlogpost: (dataObject) => dispatch(fetchSingleBlogpost(dataObject)),
        fetchBlogposts: (dataObject) => dispatch(fetchBlogposts(dataObject)),
        changeUrl: (url) => dispatch(replace(`${url}`)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Blog)