import {Modal} from "@material-ui/core";
import React from "react";
import './AddProjects.css'
import {Text} from "../text/Text";
import TextButton from "../button/TextButton";

export function AddProjects(props) {
    return <Modal
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        open={open}
        onClose={() => {
            props.hideModals()
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <div className='addProjectsMainContainer'>

            <Text headline light>Add projects from:</Text>

            <div className='addProjectsOptionsContainer'>
                <div className='addProjectsOption' onClick={() => {
                    props.showConnectAccount()
                }}>
                    <Text light>New Account</Text>
                </div>
                <div className='addProjectsOption' onClick={() => {
                    props.showUntrackedProjects()
                }}>
                    <Text light>Connected Account</Text>
                </div>
            </div>

            <button
                className={'close-button'}
                onClick={() => {
                props.hideModals()
            }}>Close</button>
        </div>
    </Modal>;
}