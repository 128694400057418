import {generateUniqueId} from "../../../../util/utils";
import testLogo1 from '../../../../images/demo/1_test_company_logo.png'
import testLogo4 from '../../../../images/demo/4_test_company_logo.png'
import testLogo5 from '../../../../images/demo/5_test_company_logo.png'
import testLogo6 from '../../../../images/demo/6_test_company_logo.png'
import testLogo7 from '../../../../images/demo/7_test_company_logo.png'
import testLogo8 from '../../../../images/demo/8_test_company_logo.png'
import testLogo9 from '../../../../images/demo/9_test_company_logo.png'
import testUser1 from '../../../../images/demo/users/1_test_user.jpeg'
import testUser2 from '../../../../images/demo/users/2_test_user.jpeg'
import testUser3 from '../../../../images/demo/users/3_test_user.jpeg'
import testUser4 from '../../../../images/demo/users/4_test_user.jpeg'
import testUser6 from '../../../../images/demo/users/6_test_user.jpeg'

export function getTestProjects() {
    return [
        {
            id: 'CC-1-test-test',
            key: 'CC',
            displayName: 'CC',
            metaData: {
                platform: 'jira',
                domain: 'test',
                disabled: false
            },
            projectMeta: {
                avatarUrl: testLogo1,
            },
            issues: [
                {
                    id: generateUniqueId(4),
                    key: 'CC-144',
                    displayName: 'CC-144',
                    fields: {
                        description: "This is a sample description.",
                        summary: 'Add Firebase Analytics to Android',
                        project: {
                            key: 'CC',
                            id: 'CC-1-test-test'
                        },
                        priority: {
                            name: 'High',
                            id: '2'
                        },
                        assignee: {
                            avatarUrls: {
                                '48x48': testUser6
                            },
                        },
                        status: {
                            id: '6',
                            statusCategory: {
                                self: 'https://prepublic.atlassian.net/rest/api/2/statuscategory/3',
                                id: 3,
                                key: 'done',
                                colorName: 'green',
                                name: 'Done'
                            }
                        },
                    },
                    statuses: [
                        {
                            name: 'Backlog',
                            id: '1',
                        },
                        {
                            name: 'In Progress',
                            id: '2',
                        },
                        {
                            name: 'On Hold',
                            id: '3',
                        },
                        {
                            name: 'Ready',
                            id: '4',
                        },
                    ],
                    metaData: {
                        newComments: [],
                        lastSeen: '2021-09-06T13:51:39.372Z'
                    }
                },
                {
                    id: generateUniqueId(4),
                    key: 'CC-189',
                    displayName: 'CC-189',
                    fields: {
                        description: "This is a sample description.",
                        summary: 'Implement Consent Layer',
                        project: {
                            key: 'CC',
                            id: 'CC-1-test-test'
                        },
                        priority: {
                            self: 'https://prepublic.atlassian.net/rest/api/2/priority/3',
                            iconUrl: 'https://prepublic.atlassian.net/images/icons/priorities/medium.svg',
                            name: 'Medium',
                            id: '3'
                        },
                        assignee: {
                            avatarUrls: {
                                '48x48': testUser6
                            },
                            displayName: 'Pavel Dimov',
                        },
                        status: {
                            id: '6',
                            statusCategory: {
                                self: 'https://prepublic.atlassian.net/rest/api/2/statuscategory/3',
                                id: 3,
                                key: 'done',
                                colorName: 'green',
                                name: 'Done'
                            }
                        },
                    },
                    statuses: [
                        {
                            name: 'Backlog',
                            id: '1',
                        },
                        {
                            name: 'In Progress',
                            id: '2',
                        },
                        {
                            name: 'On Hold',
                            id: '3',
                        },
                        {
                            name: 'Ready',
                            id: '4',
                        },
                    ],
                    metaData: {
                        newComments: [],
                        lastSeen: '2021-09-06T13:51:39.372Z'
                    }
                },
                {
                    id: generateUniqueId(4),
                    key: 'CC-75',
                    displayName: 'CC-75',
                    fields: {
                        description: "This is a sample description.",
                        summary: "Deeplink Doesn't Open On Cold-Start ",
                        project: {
                            key: 'CC',
                            id: 'CC-1-test-test'
                        },
                        priority: {
                            self: 'https://prepublic.atlassian.net/rest/api/2/priority/3',
                            iconUrl: 'https://prepublic.atlassian.net/images/icons/priorities/medium.svg',
                            name: 'Medium',
                            id: '3'
                        },
                        assignee: {
                            accountId: '5cc0172ff345850e7270e888',
                            emailAddress: 'pd@prepublic.de',
                            avatarUrls: {
                                '48x48': testUser6,
                            },
                            displayName: 'Pavel Dimov',
                        },
                        status: {
                            id: '1',
                            statusCategory: {
                                self: 'https://prepublic.atlassian.net/rest/api/2/statuscategory/3',
                                id: 3,
                                key: 'done',
                                colorName: 'green',
                                name: 'Done'
                            }
                        },
                    },
                    statuses: [
                        {
                            name: 'Backlog',
                            id: '1',
                        },
                        {
                            name: 'In Progress',
                            id: '2',
                        },
                        {
                            name: 'On Hold',
                            id: '3',
                        },
                        {
                            name: 'Ready',
                            id: '4',
                        },
                    ],
                    metaData: {
                        newComments: [],
                        lastSeen: '2021-09-06T13:51:39.372Z'
                    }
                },
            ]
        },
        {
            id: 'KENO-21-test-test',
            key: 'KENO',
            displayName: 'KENO',
            metaData: {
                platform: 'trello',
                domain: 'test',
                disabled: false
            },
            projectMeta: {
                avatarUrl: testLogo5
            },
            issues: [
                {
                    id: generateUniqueId(4),
                    key: 'KENO-7',
                    displayName: 'KENO-7',
                    fields: {
                        description: "This is a sample description.",
                        summary: 'Create Mock-Ups for Landing Page 2.0',
                        project: {
                            key: 'KENO',
                            id: 'KENO-21-test-test'
                        },
                        priority: {
                            self: 'https://prepublic.atlassian.net/rest/api/2/priority/3',
                            iconUrl: 'https://prepublic.atlassian.net/images/icons/priorities/medium.svg',
                            name: 'High',
                            id: '2'
                        },
                        assignee: {
                            avatarUrls: {
                                '48x48': testUser6
                            },
                        },
                        status: {
                            id: '6',
                            statusCategory: {
                                self: 'https://prepublic.atlassian.net/rest/api/2/statuscategory/3',
                                id: 3,
                                key: 'done',
                                colorName: 'green',
                                name: 'Done'
                            }
                        },
                    },
                    statuses: [
                        {
                            name: 'In Progress',
                            id: '2',
                        },
                        {
                            name: 'Backlog',
                            id: '1',
                        },
                        {
                            name: 'On Hold',
                            id: '3',
                        },
                        {
                            name: 'Ready',
                            id: '4',
                        },
                    ],
                    metaData: {
                        newComments: ["","","","",""],
                        lastSeen: '2021-09-06T13:51:39.372Z'
                    }
                },
            ]
        },
        {
            id: 'WAS-2000-test-test',
            key: 'WAS',
            displayName: 'WAS',
            metaData: {
                platform: 'taskchain',
                domain: 'test',
                disabled: false
            },
            projectMeta: {
                avatarUrl: testLogo6
            },
            issues: []
        },
        {
            id: 'NOX-123-test-test',
            key: 'NOX',
            displayName: 'NOX',
            metaData: {
                platform: 'jira',
                domain: 'test',
                disabled: false
            },
            projectMeta: {
                avatarUrl: testLogo7
            },
            issues: []
        },
        {
            id: 'KR-44-test-test',
            key: 'KR',
            displayName: 'KR',
            metaData: {
                platform: 'taskchain',
                domain: 'test',
                disabled: false
            },
            projectMeta: {
                avatarUrl: testLogo9
            },
            issues: []
        },
        {
            id: 'ZZ-33-test-test',
            key: 'ZZ',
            displayName: 'ZZ',
            metaData: {
                platform: 'trello',
                domain: 'test',
                disabled: false
            },
            projectMeta: {
                id: '33',
                key: 'ZZ',
                avatarUrl: testLogo8
            },
            issues: [
                {
                    id: generateUniqueId(4),
                    key: 'ZZ-704',
                    displayName: 'ZZ-704',
                    fields: {
                        description: "This is a sample description.",
                        summary: 'Performance Optimisations',
                        project: {
                            key: 'ZZ',
                            id: 'ZZ-33-test-test'
                        },
                        priority: {
                            self: 'https://prepublic.atlassian.net/rest/api/2/priority/3',
                            iconUrl: 'https://prepublic.atlassian.net/images/icons/priorities/medium.svg',
                            name: 'High',
                            id: '2'
                        },
                        assignee: {
                            avatarUrls: {
                                '48x48': testUser6
                            },
                        },
                        status: {
                            id: '6',
                            statusCategory: {
                                self: 'https://prepublic.atlassian.net/rest/api/2/statuscategory/3',
                                id: 3,
                                key: 'done',
                                colorName: 'green',
                                name: 'Done'
                            }
                        },
                    },
                    statuses: [
                        {
                            name: 'On Hold',
                            id: '3',
                        },
                        {
                            name: 'Backlog',
                            id: '1',
                        },
                        {
                            name: 'In Progress',
                            id: '2',
                        },
                        {
                            name: 'Ready',
                            id: '4',
                        },
                    ],
                    metaData: {
                        newComments: [],
                        lastSeen: '2021-09-06T13:51:39.372Z'
                    }
                }
            ]
        },
        {
            id: '21YEL-772-test-test',
            key: '21YEL',
            displayName: '21YEL',
            metaData: {
                platform: 'jira',
                domain: 'test',
                disabled: false
            },
            projectMeta: {
                avatarUrl: testLogo4
            },
            issues: [
            ]
        },
    ]
}

export function getTestComments() {
    return [
        {
            created: new Date().toString(),
            comment: {
                author :{
                    displayName: "Levi Peters",
                },
                body: "This is a sample comment message."
            }
        },
        {
            created: new Date().toString(),
            comment: {
                author :{
                    displayName: "Levi Peters",
                },
                body: "This is a sample comment message."
            }
        },
        {
            created: new Date().toString(),
            comment: {
                author :{
                    displayName: "Levi Peters",
                },
                body: "This is a sample comment message."
            }
        },
        {
            created: new Date().toString(),
            comment: {
                author :{
                    displayName: "Levi Peters",
                },
                body: "This is a sample comment message."
            }
        },
        {
            created: new Date().toString(),
            comment: {
                author :{
                    displayName: "Levi Peters",
                },
                body: "This is a sample comment message."
            }
        },
        {
            created: new Date().toString(),
            comment: {
                author :{
                    displayName: "Levi Peters",
                },
                body: "This is a sample comment message."
            }
        },
        {
            created: new Date().toString(),
            comment: {
                author :{
                    displayName: "Levi Peters",
                },
                body: "This is a sample comment message."
            }
        },
        {
            created: new Date().toString(),
            comment: {
                author :{
                    displayName: "Levi Peters",
                },
                body: "This is a sample comment message."
            }
        },
        {
            created: new Date().toString(),
            comment: {
                author :{
                    displayName: "Levi Peters",
                },
                body: "This is a sample comment message."
            }
        },
        {
            created: new Date().toString(),
            comment: {
                author :{
                    displayName: "Levi Peters",
                },
                body: "This is a sample comment message."
            }
        },
        {
            created: new Date().toString(),
            comment: {
                author :{
                    displayName: "Levi Peters",
                },
                body: "This is a sample comment message."
            }
        },
        {
            created: new Date().toString(),
            comment: {
                author :{
                    displayName: "Levi Peters",
                },
                body: "This is a sample comment message."
            }
        }
        //{this.state.comments.map(comment => {
        //                                 //TODO show avatars and names of the people
        //                                 let createDate = new Date(comment.created)
        //                                 let dateTimeString = `${createDate.toDateString()} ${createDate.getHours()}:${createDate.getMinutes()}:${createDate.getSeconds()}`
        //                                 console.log(JSON.stringify(comment));
        //                                 return <div className='singleComment'>
        //                                     <div className='singleCommentTitle'>
        //                                         <span>{`${dateTimeString} `}</span>
        //                                         <span className='highlightedUser'>{`${comment.author.displayName}:`}</span>
        //                                     </div>
        //                                     {this.getTextWithAvatars(comment.body, this.props.usersData)}
        //                                 </div>
        //                             })}
    ]
}

export function getTestUsers() {
    return {
        users: [
            {
                accountId: 'test1',
                avatarUrls: {
                    '48x48': testUser1,
                },
                displayName: 'Levi Peters',
            },
            {
                accountId: 'test2',
                avatarUrls: {
                    '48x48': testUser2,
                },
                displayName: 'Nora Davis',
            },
            {
                accountId: 'test3',
                avatarUrls: {
                    '48x48': testUser3,
                },
                displayName: 'Margie Berry',
            },
            {
                accountId: 'test4',
                avatarUrls: {
                    '48x48': testUser4,
                },
                displayName: 'Don Elliott',
            },
        ]
    }
}