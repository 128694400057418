import {ENTRY_FUNCTION_URL} from "../../Constants";

export class TrelloConnector {

    async setTokenAndFetchProjects(dataObject) {
        let data = {
            username: dataObject.username,
            platform: dataObject.platform,
            event: {
                type: 'save_token_and_fetch_projects',
                data: {
                    token: dataObject.token
                }
            }
        }
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        let projectsData = {}
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body)
            if (body) {
                projectsData = body.data.projects
            }
        }
        return projectsData
    }


    async addProjects(dataObject) {
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: dataObject.username,
                platform: dataObject.platform,
                event: {
                    data: {
                        connectionId: dataObject.connectionId,
                        projectsData: dataObject.selectedProjects,
                    },
                    type: 'add_projects'
                }
            })
        });

        let projectsData = []
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body)
            if (body) {
                projectsData = body.data
            }
        }

        return projectsData
    }

    async disableProject(dataObject) {
        let requestData = {
            username: dataObject.username,
            platform: dataObject.platform,
            event: {
                type: 'disable_project',
                data: {
                    connectionId: dataObject.connectionId,
                    projectId: dataObject.projectId
                }
            }
        }

        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let data = await response.json();
        let body = JSON.parse(data.body)
        if (body) {
            return body.data.disabledProjectId
        }
        return [];
    }

    async enableProject(dataObject) {
        let requestData = {
            username: dataObject.username,
            platform: dataObject.platform,
            event: {
                type: 'enable_project',
                data: {
                    connectionId: dataObject.connectionId,
                    projectId: dataObject.projectId
                }
            }
        }

        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let data = await response.json();
        let body = JSON.parse(data.body)
        if (body) {
            return body.data.enabledProjectId
        }
        return [];
    }

    async removeProject(dataObject) {
        let requestData = {
            username: dataObject.username,
            platform: dataObject.platform,
            event: {
                type: 'remove_project',
                data: {
                    connectionId: dataObject.connectionId,
                    projectKey: dataObject.projectKey,
                    projectId: dataObject.projectId
                }
            }
        }

        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let data = await response.json();
        let body = JSON.parse(data.body)
        if (body) {
            return body.data
        }
        return [];
    }

    async transitionIssue(dataObject) {
        let data = {
            username: dataObject.username,
            platform: dataObject.platform,
            event: {
                type: 'transition_issue',
                data: {
                    connectionId: dataObject.connectionId,
                    issueKey: dataObject.updateData.issueKey,
                    newStatusId: dataObject.updateData.newStatusId,
                    newStatusName: dataObject.updateData.newStatusName,
                }
            }
        }

        await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }
}