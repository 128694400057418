import {
    BASE_URL,
    ENTRY_FUNCTION_URL, MONTHLY_PREMIUM_PLAN_PRODUCTION_PRICE_ID,
    YEARLY_PREMIUM_PLAN_PRODUCTION_PRICE_ID,
    YEARLY_PREMIUM_PLAN_PRODUCTION_PRICE_ID_TEST
} from "../../Constants";

export class StripeConnector {
    async createPaymentSession(dataObject, autoRedirectToCheckout) {
        const testMode = window.location.host === "test.xlens.io";
        let requestData = {
            userEmail: dataObject.userEmail,
            organisationId: dataObject.organisationId,
            priceId: testMode ? YEARLY_PREMIUM_PLAN_PRODUCTION_PRICE_ID_TEST : MONTHLY_PREMIUM_PLAN_PRODUCTION_PRICE_ID,
            testMode: testMode
        }

        let response = await fetch(`${BASE_URL}/payment/create-checkout-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let sessionUrl = null
        if (response) {
            const session = await response.json();
            sessionUrl = session.data
            if (sessionUrl && autoRedirectToCheckout) {
                window.location.replace(sessionUrl);
            }
        }
        return sessionUrl
    }

    async cancelSubscription(username) {
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username,
                platform: 'stripe',
                event: {
                    type: 'cancel_subscription',
                }
            })
        });

        let newUserSubscriptionData = null;
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body)
            if (body) {
                newUserSubscriptionData = body.data.newUserSubscriptionData
            }
        }
        return newUserSubscriptionData
    }

    async reactivateSubscription(username) {
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username,
                platform: 'stripe',
                event: {
                    type: 'reactivate_subscription',
                }
            })
        });

        let newUserSubscriptionData = null;
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body)
            if (body) {
                newUserSubscriptionData = body.data.newUserSubscriptionData
            }
        }
        return newUserSubscriptionData
    }
}
