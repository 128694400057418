import {ReactComponent as Jira} from "../images/logo_jira.svg";
import {ReactComponent as Trello} from "../images/logo_trello.svg";
import {ReactComponent as Asana} from "../images/asana_logo.svg";
import React, {useEffect, useState} from "react";
import {Text} from "../components/text/Text";
import taskchain from '../images/taskchain_logo.png'
import {v4 as uuidv4} from 'uuid'



export function generateUniqueId(length) {
    return '_' + Math.random().toString(36).substr(2, length ? length : 9);
}

export function moveInArray(arr, fromIndex, toIndex) {
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr
}

export function getAverageRGB(imgEl) {
    let blockSize = 5, // only visit every 5 pixels
        defaultRGB = {r: 0, g: 0, b: 0}, // for non-supporting envs
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data, width, height,
        i = -4,
        length,
        rgb = {r: 0, g: 0, b: 0},
        count = 0;

    if (!context) {
        return defaultRGB;
    }

    height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

    context.drawImage(imgEl, 0, 0);

    try {
        data = context.getImageData(0, 0, width, height);
    } catch (e) {
        /* security error, img on diff domain */
        return defaultRGB;
    }

    length = data.data.length;

    while ((i += blockSize * 4) < length) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i + 1];
        rgb.b += data.data[i + 2];
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r / count);
    rgb.g = ~~(rgb.g / count);
    rgb.b = ~~(rgb.b / count);

    return rgb;
}

export function getPlatformLogo(platform) {
    switch (platform) {
        case 'jira':
            return <Jira style={{opacity:0.5, marginRight: 10, width: 50, height: 50}}/>
        case 'trello':
            return <Trello style={{opacity:0.5, marginRight: 10, width: 50, height: 50}}/>
        case 'asana':
            return <Asana style={{opacity:0.5, marginRight: 10, width: 50, height: 50}}/>
        case 'taskchain':
            return <img src={taskchain} style={{opacity:0.5, marginRight: 10, height: 50}} />
    }
}

export function getReadableValueFromSeconds(seconds, excludeSeconds) {
    let weeks = Math.floor(seconds / (3600 * 24 * 7));
    seconds -= weeks * 3600 * 24 * 7;
    let days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    let hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    let minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    let result = "";
    if (weeks !== 0) {
        result = `${weeks}w\n`;
    }
    if (days !== 0) {
        result = `${result} ${days}d\n`;
    }
    if (hours !== 0) {
        result = `${result} ${hours}h\n`;
    }
    if (minutes !== 0) {
        result = `${result} ${minutes}m\n`
    }
    if (seconds !== 0 && !excludeSeconds) {
        result = `${result} ${seconds}s`
    }

    return result;
}

export function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export function getPrettyDate(date) {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yy = date.getFullYear().toString().substring(2);

    return `${dd}.${mm}.${yy}`;
}

export function getTimestampFromStartOfDay(dateTimestamp) {
    let now = new Date(dateTimestamp);
    return new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()
}

export function generateRandomColor() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`
}

export function roundToTwoDecimals(number) {
    return Math.round((number + Number.EPSILON) * 100) / 100
}

export function generateUuid() {
    return uuidv4()
}
