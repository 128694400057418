import React from 'react'
import './Demo.css'
import DemoChain from "./demochain/DemoChain";
import DemoDivider from "./demodivider/DemoDivider";

export class Demo extends React.Component {
    render() {
        return (
            <div className='demoRegionMainContainer'>
                <DemoDivider/>
                <DemoChain/>
            </div>
        );
    }
}