import './Invitations.css'
import React from "react";
import {GeneralConnector} from "../../connectors/general/GeneralConnector";
import {Modal, Snackbar} from "@material-ui/core";
import {Text} from "../../components/text/Text";
import {StyledButton} from "../../components/button/StyledButton";

export default class Invitations extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: null
        }
        this.generalConnector = new GeneralConnector();
    }

    componentDidMount() {
        this.maybeCheckInvitationValidity()
    }

    async handleAccept(invitation) {
        this.setState({loading:true})

        const result = await this.generalConnector.acceptInvitation({
            invitationId: invitation._id,
            organisationId: invitation.organisationId
        })

        console.log("accept invitation", result);
        this.props.openApp();
    }

    async maybeCheckInvitationValidity() {
        if (this.props.invitationId) {
            let invitation = await this.generalConnector.fetchInvitationStatus({
                username: 'unknown_user',
                invitationId: this.props.invitationId
            })

            this.setState({
                invitation
            })
        }
    }


    render() {
        let invitation = this.state.invitation ? this.state.invitation : null;
        let expirationDate = invitation ? new Date(invitation.expirationTimestamp).toDateString() : null;

        return <Modal
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            open={true}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <div className='invitationsMainContainer'>
                <Text fontSize={27} light marginTop={10} marginBottom={40}>Invitation</Text>
                <div className='invitationsContainer'>
                    <div className='invitationsHorizontalContainer'>
                        <div className='invitationsVerticalContainer'>
                            <Text headling light marginBottom={10}>Sender</Text>
                            {invitation ? <Text light fontSize={12} opacity={0.8} >{invitation.senderEmail}</Text> : null}
                        </div>
                        <div className='invitationsVerticalContainer'>
                            <Text headling light marginBottom={10}>Receiver</Text>
                            {invitation ? <Text light fontSize={12} opacity={0.8} >{invitation.receiverEmail}</Text> : null}

                        </div>

                        <div className='invitationsVerticalContainer'>
                            <Text headling light marginBottom={10}>Status</Text>
                            <Text light fontSize={12} opacity={0.8} >{invitation ? "Active" : "Inactive"}</Text>
                        </div>
                        <div className='invitationsVerticalContainer'>
                            <Text headling light marginBottom={10}>Expires on</Text>
                            {expirationDate ? <Text opacity={0.8} light fontSize={12}>{expirationDate}</Text> : null}
                        </div>
                    </div>
                    <StyledButton onClick={() => {
                        this.handleAccept(invitation)
                    }} marginTop={40} marginBottom={20} disabled={invitation===true}>Accept</StyledButton>
                </div>
            </div>
        </Modal>

    }

}
