import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {Auth} from "aws-amplify";
import {JiraConnector} from "../connectors/jira/JiraConnector";
import {StripeConnector} from "../connectors/stripe/StripeConnector";
import {GeneralConnector} from "../connectors/general/GeneralConnector";
import {push} from "connected-react-router";

const thunkPrefix = 'users';
const jiraConnector = new JiraConnector();
const stripeConnector = new StripeConnector();
const generalConnector = new GeneralConnector();

export const createAccount = createAsyncThunk(`${thunkPrefix}/createAccount`, async (dataObject, thunkAPI) => {
    let errorMessage = null;
    let usr = null;
    try {
        const {user} = await Auth.signUp({
            username: dataObject.email,
            password: dataObject.password
        });

        if (user) {
            let userData = await generalConnector.createUser({
                email: dataObject.email,
                name: dataObject.name,
                invitationId: dataObject.invitationId
            });

            if (userData) {
                thunkAPI.dispatch(push('/login'))
                return {
                    user: null,
                    errorMessage: null,
                    initiatePaymentSessionOnCreation: false
                };
            }
            return {
                user: usr,
                errorMessage: null,
                initiatePaymentSessionOnCreation: dataObject.initiatePaymentSessionOnCreation
            };
        }
    } catch (e) {

        return {
            user: null,
            errorMessage: e.message,
            initiatePaymentSessionOnCreation: dataObject.initiatePaymentSessionOnCreation
        };
    }
});

export const confirmSignUp = createAsyncThunk(`${thunkPrefix}/confirmSignUp`, async (dataObject, thunkAPI) => {
    let confirmationResult = null;
    if (dataObject && dataObject.email && dataObject.code) {
        let confirmationResult = await Auth.confirmSignUp(this.state.email, this.state.code);
    }
    return {
        confirmationResult
    }
});

export const login = createAsyncThunk(`${thunkPrefix}/login`, async (loginData, thunkAPI) => {
    let user = null;
    let errorMessage = null;
    if (loginData && loginData.email && loginData.password) {
        try {
            let userResponse = await Auth.signIn(loginData.email, loginData.password);
            if (userResponse && userResponse.attributes && userResponse.attributes.email) {
                let email = userResponse.attributes.email;
                let userData = await generalConnector.fetchUser(email)

                //TODO change when adding support for many organisations

                if (userData) {
                    let mainOrganisationId = userData.user.organisations[0];
                    let mainOrganisation = userData.organisations.find(org => org._id === mainOrganisationId);

                    user = {
                        userEmail: email, userData: userData.user, subscriptionStatus: getSubscriptionStatus(mainOrganisation)
                    };
                }
            }
        } catch (e) {
            errorMessage = e.message;
        }
    }

    return {
        user: user,
        errorMessage: errorMessage
    }
});

function getSubscriptionStatus(organisation) {
    let isSubscribed = false;
    let currentPeriodEndDate = null;

    if (organisation && organisation.subscriptionData) {
        currentPeriodEndDate = new Date()
        currentPeriodEndDate.setTime(organisation.subscriptionData.currentPeriodEndTimestamp);

        isSubscribed = currentPeriodEndDate > new Date();
    }

    return {
        isSubscribed, currentPeriodEndDate
    }
}

export const setErrorMessage = createAsyncThunk(`${thunkPrefix}/setErrorMessage`, async (errorMessage, thunkAPI) => {
    return {
        errorMessage
    }
});

export const logout = createAsyncThunk(`${thunkPrefix}/logout`, async (dataObject, thunkAPI) => {
    await Auth.signOut();
    return {
        user: null
    }
});

export const resendConfirmationCode = createAsyncThunk(`${thunkPrefix}/resendConfirmationCode`, async (dataObject, thunkAPI) => {
    await Auth.resendSignUp(this.state.email);

    return {
        user: null
    }
});

export const sendInvitation = createAsyncThunk(`${thunkPrefix}/sendInvitation`, async (dataObject, thunkAPI) => {
    const invitationData = await generalConnector.sendInvitation(dataObject);
    return {
        invitationData
    }
});

export const getCurrentUser = createAsyncThunk(`${thunkPrefix}/getCurrentUser`, async (dataObject, thunkAPI) => {
    let user = null;

    let userResponse = await Auth.currentAuthenticatedUser();
    if (userResponse && userResponse.attributes && userResponse.attributes.email) {
        let email = userResponse.attributes.email;
        let userData = await generalConnector.fetchUser(email)

        if (userData) {
            let mainOrganisationId = userData.user.organisations[0];
            let mainOrganisation = userData.organisations.find(org => org._id === mainOrganisationId);

            user = {
                userEmail: email, userData: userData.user, subscriptionStatus: getSubscriptionStatus(mainOrganisation)
            };
        }
    }

    return {
        user: user
    }
});

export const fetchUsersForOrganisation = createAsyncThunk(`${thunkPrefix}/fetchUsersForOrganisation`, async (organisationId, thunkAPI) => {
    const usersAndInvitations = await generalConnector.fetchUsersForOrganisation(organisationId);
    return {usersAndInvitations}
});

export const cancelSubscription = createAsyncThunk(`${thunkPrefix}/cancelSubscription`, async (dataObject, thunkAPI) => {
    let newUserSubscriptionStatus = await stripeConnector.cancelSubscription(dataObject.email);
    return {
        newUserSubscriptionStatus
    }
});

export const reactivateSubscription = createAsyncThunk(`${thunkPrefix}/reactivateSubscription`, async (dataObject, thunkAPI) => {
    let newUserSubscriptionStatus = await stripeConnector.reactivateSubscription(dataObject.email);
    return {
        newUserSubscriptionStatus
    }
});

export const updateAccessLevel = createAsyncThunk(`${thunkPrefix}/updateAccessLevel`, async (dataObject, thunkAPI) => {
    let newUserSubscriptionStatus = await generalConnector.updateAccessLevel(dataObject.userEmail, dataObject.newAccessLevel);
    return {
        newUserSubscriptionStatus
    }
});

const userSlice = createSlice({
    name: thunkPrefix,
    initialState: {
        initiatePaymentSession: false,
        users: [],
        openInvitations: [],
        currentUser: null,
        errorMessage: null
    },
    extraReducers: {
        [createAccount.fulfilled]: (state, action) => {
            if (action.payload.user) {
                if (action.payload.initiatePaymentSessionOnCreation) {
                    state.initiatePaymentSession = true;
                }
            } else {
                state.errorMessage = action.payload.errorMessage;
            }
            state.currentUser = action.payload.user;
        },
        [createAccount.rejected]: (state, action) => {
            //todo handle
        },
        [confirmSignUp.fulfilled]: (state, action) => {
            // TODO action.payload.confirmationResult -  show message;
        },
        [confirmSignUp.rejected]: (state, action) => {
            // TODO action.payload.confirmationResult -  show message;
        },
        [login.fulfilled]: (state, action) => {
            let user = action.payload.user;
            state.currentUser = action.payload.user;
            if (!user) {
                state.errorMessage = action.payload.errorMessage;
            }
        },
        [login.rejected]: (state, action) => {
            //todo handle
        },
        [logout.fulfilled]: (state, action) => {
            state.currentUser = action.payload.user;
        },
        [logout.rejected]: (state, action) => {
            //todo handle
        },
        [resendConfirmationCode.fulfilled]: (state, action) => {
            //todo handle
        },
        [resendConfirmationCode.rejected]: (state, action) => {
            //todo handle
        },
        [getCurrentUser.fulfilled]: (state, action) => {
            state.currentUser = action.payload.user;
        },
        [getCurrentUser.rejected]: (state, action) => {
            //todo handle
        },
        [setErrorMessage.fulfilled]: (state, action) => {
            state.errorMessage = action.payload.errorMessage;
        },
        [setErrorMessage.rejected]: (state, action) => {
            //todo handle
        },
        [cancelSubscription.fulfilled]: (state, action) => {
            state.currentUser.subscriptionStatus.subscriptionData = action.payload.newUserSubscriptionStatus;
        },
        [cancelSubscription.rejected]: (state, action) => {
            //todo handle
        },
        [reactivateSubscription.fulfilled]: (state, action) => {
            state.currentUser.subscriptionStatus.subscriptionData = action.payload.newUserSubscriptionStatus;
        },
        [reactivateSubscription.rejected]: (state, action) => {
            //todo handle
        },
        [fetchUsersForOrganisation.fulfilled]: (state, action) => {
            state.users = action.payload.usersAndInvitations.users;
            state.openInvitations = action.payload.usersAndInvitations.invitations;
        },
        [fetchUsersForOrganisation.rejected]: (state, action) => {
            //todo handle
        }
    }
});

export const userReducer = userSlice.reducer;
