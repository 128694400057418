import React from "react";
import "./TextWithImage.css";
import Chip from '@material-ui/core/Chip';

export default function TextWithImage({
                                        text,
                                        chipLabel,
                                        chipColor,
                                        textHeader,
                                        imageSrc,
                                        imageAlt,
                                      }) {


  return (
      <div className={`textWithImageContainer`}>
          <div className="textImageCollection">
            <div className="textContainer">
              <Chip label={chipLabel.toUpperCase()}  style={{color: "white", backgroundColor: chipColor, display: "flex", alignItems: "center", marginBottom: "12px", padding: "4px 12px"}} />
              <h1>{textHeader}</h1>
              <p>{text}</p>
            </div>
            <img src={imageSrc} alt={imageAlt} className={'image-style'} />
          </div>
      </div>
  );
}
