import React from "react";
import './Tasks.css'
import TaskWeb from "./task/web/TaskWeb";
import TaskMobile from "./task/mobile/TaskMobile";

export default class Tasks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: screen.width,
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({width: screen.width});
    };

    render() {
        let issuesData = this.props.issuesData;
        if (this.props.selectedProjectId) {
            issuesData = issuesData.filter(issuesData => issuesData.projectId === this.props.selectedProjectId);
        }
        let useMobileLayout = this.state.width && this.state.width < 850;
        let tasksComponent = issuesData?.map(issueData => {
            let issue = issueData.issue;
            let id = issue.id;
            if (useMobileLayout) {
                return (
                    <TaskMobile
                        toggleBookmark={this.props.toggleBookmark}
                        currentlyTrackedIssue={this.props.currentlyTrackedIssue}
                        readOnly={this.props.readOnly}
                        showEditTask={this.props.showEditTask}
                        estimate={issueData.estimate}
                        testMode={this.props.testMode}
                        removeTestTask={this.props.removeTestTask}
                        projectId={issueData.projectId}
                        key={id}
                        issueData={issueData}
                        openInfoBox={this.props.openInfoBox}/>
                );
            }else {
                return (
                    <TaskWeb
                        toggleBookmark={this.props.toggleBookmark}
                        currentlyTrackedIssue={this.props.currentlyTrackedIssue}
                        readOnly={this.props.readOnly}
                        showEditTask={this.props.showEditTask}
                        estimate={issueData.estimate}
                        testMode={this.props.testMode}
                        removeTestTask={this.props.removeTestTask}
                        projectId={issueData.projectId}
                        key={id}
                        issueData={issueData}
                        openInfoBox={this.props.openInfoBox}/>
                );
            }
        });
        return (
            <div className='mainContentContainer'>
                {issuesData.length > 0 ?
                    <div className='tasksMainContainer'>
                        {tasksComponent}
                    </div> :
                    <div className='noTasksContainer'>
                        <span className='noTasksText'>No tasks for the current selection.</span>
                    </div>}

            </div>
        );
    }
}
