import React from "react";
import {Typography} from "@material-ui/core";

export function Text(props) {

    function getFontStyle() {
        if (props.bold) {
            return 'bold'
        } else if (props.italic) {
            return 'italic';
        } else {
            return 'normal'
        }
    }

    function getFontSize() {
        return props.fontSize ? props.fontSize : 16;
    }

    function getColor() {
        if (props.error) {
            return 'var(--main-color)';
        }

        return props.color ? props.color : 'black';
    }

    let color = props.light ? 'white' : getColor();

    let fontSize = props.headline ? 21 : getFontSize();

    if (props.fontSize) fontSize = props.fontSize;

    let alignSelf = props.alignSelf ? props.alignSelf : 'center';
    let textAlign = props.textAlign ? props.textAlign : 'center';
    let opacity = props.opacity ? props.opacity : 0.8;
    let marginTop = props.marginTop ? props.marginTop : 0;
    let marginBottom = props.marginBottom ? props.marginBottom : 0;
    let marginLeft = props.marginLeft ? props.marginLeft : 0;
    let marginRight = props.marginRight ? props.marginRight : 0;
    let width = props.width ? props.width : 'auto';
    let height = props.height ? props.height : 'auto';
    let cursor = props.cursor ? props.cursor : 'default';
    let paddingLeft = props.paddingLeft ? props.paddingLeft : 0;
    let paddingRight = props.paddingRight ? props.paddingRight : 0;
    let paddingTop = props.paddingTop ? props.paddingTop : 0;
    let paddingBottom = props.paddingBottom ? props.paddingBottom : 0;

    let fontStyle = getFontStyle()

    let style = {
        color,
        cursor,
        width,
        height,
        fontSize,
        fontStyle,
        marginTop,
        marginLeft,
        marginRight,
        marginBottom,
        opacity,
        alignSelf,
        textAlign,
        paddingLeft,
        paddingRight,
        paddingTop,
        paddingBottom
    }

    if (props.position) style.position = props.position;
    if (props.left) style.left = props.left
    if (props.top) style.top = props.top
    if (props.border) {
        style.borderWidth = 0.5
        style.borderRadius = 5;
        style.borderColor = 'white';
        style.borderStyle = "solid"
    }


    return <Typography variant='h3' onClick={props.onClick} style={style}>{props.children}</Typography>;
}
