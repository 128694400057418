import React from "react";
import {HeadlineText} from "../../../components/headlinetext/HeadlineText";
import {SecondaryText} from "../../../components/secondarytext/SecondaryText";
import PricingPlan from "../../../components/pricing/PricingPlan";
import {Text} from "../../../components/text/Text";

export const HomePagePricing = (props) => {
    return <div className='homePagePricingContainer'>
        {props.disableHeader ? null : <HeadlineText>{props.headerText}</HeadlineText>}
        {props.disableDescription ? null : <SecondaryText>{props.descriptionText}</SecondaryText>}
        <PricingPlan actionOne={props.actionOne}/>
    </div>
}
