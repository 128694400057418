import {Component} from "react/cjs/react.production.min";
import React from "react";
import ProjectHolder from "../projectholder/ProjectHolder";


export default class ChainLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showActions: false,
            width: screen.width,
        }
    }

    updateDimensions = () => {
        this.setState({width: screen.width});
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    removeProject() {
        this.props.removeProject(this.props.projectId, this.props.connectionId)
    }

    enableProject() {
        this.props.enableProject(this.props.projectId, this.props.connectionId)
    }

    disableProject() {
        this.props.disableProject(this.props.projectId, this.props.connectionId)
    }

    render() {

        let projectId = this.props.projectId
        let projectKey = this.props.projectKey
        let isDisabled = this.props.projectMeta && this.props.projectMeta && this.props.projectMeta.disabled;
        //TODO imageProblem - use src property
        return <ProjectHolder
            mainUser={this.props.mainUser}
            user={this.props.user}
            removeProjectFromUser={this.props.removeProjectFromUser}
            disableAdminMode={this.props.disableAdminMode}
            hasMenu
            isPartOfChain
            displayName={this.props.displayName}
            platform={this.props.platform}
            archiveProject={this.props.archiveProject}
            showAddTask={this.props.showAddTask}
            showEditTask={this.props.showEditTask}
            disableProject={this.disableProject.bind(this)}
            enableProject={this.enableProject.bind(this)}
            removeProject={this.removeProject.bind(this)}
            showAddProjects={this.props.showAddProjects}
            selected={this.props.selected}
            disabled={isDisabled}
            projectKey={projectKey}
            projectId={projectId} selectProject={this.props.selectProject}
            color={this.props.projectMeta.color}
            image={this.props.projectMeta.avatarUrl}/>
    }
}
