import React from "react";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            // width: "50%",
            backgroundColor: "#061a28",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        heading: {
            //   fontSize: theme.typography.pxToRem(15),
            //   fontWeight: theme.typography.fontWeightRegular,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        accordion: {
            width: "50%",
            marginBottom: "10px",
        },
    })
);

export default function FAQ() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <h1 className={classes.heading}>Frequently Asked Questions</h1>
            <div className={classes.accordion}>
                <Accordion style={{backgroundColor: "#438BF7", color: "white", marginBottom: "10px"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>What is <a style={{color: "blue"}}>xlens.io</a>?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <a style={{color: "blue"}}>Xlens.io</a> is a website and mobile feedback and annotation
                            tool, who allows
                            developers and business owners to discover bugs on their websites faster.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{backgroundColor: "#438BF7", color: "white", marginBottom: "10px"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Who is <a style={{color: "blue"}}>xlens</a> for?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <a style={{color: "blue"}}>Xlens.io</a> it’s perfect for software development teams, who use
                            Jira and need
                            to collect internal feedback during development.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{backgroundColor: "#438BF7", color: "white", marginBottom: "10px"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>How does the bug tracking process work with this tool?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Our tool simplifies bug tracking by allowing you to capture screenshots, annotate them, and
                            submit
                            detailed bug reports directly from your browser. You can add context, assign tasks, and
                            monitor the
                            progress of bug resolution—all within the platform.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{backgroundColor: "#438BF7", color: "white", marginBottom: "10px"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Does the tool support capturing and annotating bugs on mobile devices?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Absolutely, our tool is mobile-friendly and supports bug capture and annotation on various
                            mobile devices.
                            You can report bugs or provide feedback conveniently, even while on the go.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{backgroundColor: "#438BF7", color: "white", marginBottom: "10px"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Can I integrate this tool with my existing project management software?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Yes, our tool offers seamless integration with popular project management tools like Jira
                            and Trello. This
                            enables smooth transfer of bug reports and feedback into your existing workflow.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{backgroundColor: "#438BF7", color: "white", marginBottom: "10px"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "white"}}/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Are there any browser limitations for using the bug capture extension?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Our browser extension is designed to work seamlessly across major browsers like Chrome,
                            Firefox, Safari,
                            and Edge, ensuring consistent bug capture functionality regardless of the browser you use.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <button
                        className={'get-started-button-bottom'}
                        onClick={() => {
                            props.openRegistration()
                        }}>Get Started
                    </button>
                </div>
            </div>
        </div>
    );
}
