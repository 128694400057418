import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {connect} from "react-redux";
import './StatusDropdown.css'

class StatusDropDown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedStatusId: props.issueStatus.id,
        }

        this.handleChange = this.handleChange.bind(this)
    }

    render() {
        let statuses = this.props.statuses ? this.props.statuses : [];

        return (
            <div className='main'>
                <FormControl variant="outlined">
                    <Select
                        native
                        className='select'
                        value={this.state.selectedStatusId}
                        onChange={(event) => this.handleChange(event)}
                        inputProps={{
                            name: 'status',
                            id: 'outlined-age-native-simple',
                        }}
                    >
                        {statuses.map(status => {
                            return (
                                <option value={status.id}>{status.name}</option>
                            )
                        })}
                    </Select>
                </FormControl>
            </div>
        );
    }

    handleChange(event) {
        const statusId = event.target.value
        const status = this.props.statuses.find(status => status.id === event.target.value);
        if (status) {
            let statusName = status.name

            this.setState({
                selectedStatusId: statusId,
            });

            this.props.updateIssue({
                updateOperation: 'transition',
                updateData: {
                    issueKey: this.props.issueKey,
                    newStatusId: statusId,
                    newStatusName: statusName
                },
                username: this.props.currentUsername,
                platform: this.props.platform,
                connectionId: this.props.connectionId,
            })
        }
    };
}

const mapStateToProps = (state) => {
    return {
        currentUsername: state.userReducer.currentUser ? state.userReducer.currentUser.username : null
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateIssue: (dataObject) => dispatch(updateIssue(dataObject)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StatusDropDown)
