import React from "react";
import "./HomePageHeader.css";
import "../../../index.css";
import HomePageHeaderMenu from "./menu/HomePageHeaderMenu";
import {LAYOUT_BREAKPOINT_PX} from "../../../Constants";
import {Logo} from "./logo/Logo";
import TextButton from "../../../components/button/TextButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Jira from "../../../images/logo-jira-blue.svg"
import Trello from "../../../images/logo-trello-blue.svg"
import {ComingSoonBadge} from "../../../components/commingsoon/ComingSoonBadge";

export const HOME = "home";
export const PRICING = "pricing";
export const PRIVACY = "privacy";
export const BLOG = "blog";

export class HomePageHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: screen.width,
            height: screen.height,
            isDarkMode: false,
            anchorEl: null
        };
    }

    updateDimensions = () => {
        this.setState({width: screen.width, height: screen.height});
    };

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    handleToggleClick = () => {
        document.documentElement.classList.toggle("dark-mode");
    };

    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    render() {
        let homeStyles =
            this.props.selectedOption === HOME
                ? "homePageOptionTextSelected"
                : "homePageOptionText";
        let pricingStyles =
            this.props.selectedOption === PRICING
                ? "homePageOptionTextSelected"
                : "homePageOptionText";
        let privacyStyles =
            this.props.selectedOption === PRICING
                ? "homePageOptionTextSelected"
                : "homePageOptionText";
        let blogStyles =
            this.props.selectedOption === BLOG
                ? "homePageOptionTextSelected"
                : "homePageOptionText";
        const {isDarkMode} = this.state;

        const classes = {
            menu: {}
        }
        return (
            <div className="homePageHeaderContainer">
                <div className={'left-side-header-container'}>
                    <div className={'logo'}>
                        <Logo/>
                    </div>
                    <div className={'integrations-container'}>
                        <TextButton color="var(--main-color)" onClick={this.handleClick}>Integrations</TextButton>

                        <Menu
                            className="integrationsMenu"
                            style={{marginTop: 25}}
                            id="xlens-integrations"
                            aria-labelledby="xlens-integrations"
                            anchorEl={this.state.anchorEl}
                            open={!!this.state.anchorEl}
                            onClose={() => {
                                this.setState({
                                    anchorEl: null
                                })
                            }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            <MenuItem style={{opacity: 1}}>
                                <img width={70} height="50%" src={Jira} alt='jira'/>
                            </MenuItem>
                            <MenuItem style={{opacity: 1}}>
                                {ComingSoonBadge(<img width={100} height="50%" src={Trello} alt='trello'/>, {
                                    width: 100,
                                    marginLeft: -30,
                                    borderTopRightRadius: 0,
                                    borderTopLeftRadius: 0
                                })}
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
                {this.state.width < LAYOUT_BREAKPOINT_PX ? (
                    <HomePageHeaderMenu
                        className="homePageOptionsContainer"
                        openPrivacy={this.props.openPrivacy}
                        openHome={this.props.showHome}
                        openPricing={this.props.showPricing}
                        openApp={this.props.openApp}
                        openBlog={this.props.openBlog}
                    />
                ) : (
                    <div className="homePageOptionsContainer">
                        <p className={homeStyles} onClick={() => {this.props.showHome();}}>
                            Home
                        </p>
                        <p className={pricingStyles} onClick={() => {this.props.showPricing();}}>
                            Pricing
                        </p>

                        <p className={blogStyles} onClick={() => {this.props.openBlog();}}>
                            Blog
                        </p>

                        <p className="homePageStatus" onClick={() => {this.props.openApp();}}>
                            {this.props.isLoggedIn ? "App" : "Sign In"}
                        </p>

                        {/* <button onClick={this.handleToggleClick}>Toggle</button> */}
                    </div>
                )}
            </div>
        );
    }
}
