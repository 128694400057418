import './Chain.css'
import React from "react";
import {connect} from "react-redux";
import ChainLink from "../../components/chainlink/ChainLink";
import {
    disableProject,
    enableProject,
    removeProject,
    addProjects,
    addProjectsBulk, setTokenAndFetchProjects,
} from "../../reducers/accountReducer";
import ConnectAccount from "../../components/account/createaccount/ConnectAccount";
import {AddProjects} from "../../components/addprojects/AddProjects";
import MultipleAccountProjectSelector, {
    SelectorType
} from "../../components/multipleAccountsProjectsSelector/MultipleAccountsProjectsSelector";
import AddIcon from '@material-ui/icons/Add'
import {generateAuthCodeUrl} from "../../connectors/jira/JiraConnector";
import {v4 as uuidv4} from 'uuid'
import {Snackbar, Tooltip} from "@material-ui/core";
import {MAXIMUM_CONNECTED_PROJECTS} from "../../Constants";
import {ModularProgressBar} from "../../components/modularprogressbar/ModularProgressBar";
import Tasks from "../tasks/Tasks";
import NewAccountPlaceholder from "../../components/projectholder/NewAccountPlaceholder";
import SingleAccountProjectSelector from '../../components/singleAccountProjectSelector/SingleAccountProjectSelector';
import TaskInfo from "../../components/comments/TaskInfo";

class Chain extends React.Component {

    constructor() {
        super();
        this.state = {
            loading: true,
            showInfoBox: false,
            showConnectAccount: false,
            showAddProjects: false,
            showUntrackedProjects: false,
            commentsAnchorEl: null,
            currentIssueKey: null,
            showMaxProjectsAllowedSnackbar: false
        }
    }

    showConnectAccount() {
        this.setState({
            showConnectAccount: true
        })
    }

    closeConnectAccount() {
        this.setState({
            showConnectAccount: false
        })
    }

    showUntrackedProjects() {
        this.setState({
            showConnectAccount: false,
            showAddProjects: false,
            showUntrackedProjects: true
        })
    }

    closeUntrackedProjects() {
        this.setState({
            showUntrackedProjects: false,
            showConnectAccount: false,
            showAddProjects: true
        })
    }

    showAddProjects() {
        this.setState({
            showAddProjects: true
        })
    }

    closeAddProjects() {
        this.setState({
            showUntrackedProjects: false,
            showConnectAccount: false,
            showAddProjects: false
        })
    }

    hideModals() {
        this.setState({
            showConnectAccount: false,
            showAddProjects: false,
            showAddTask: false,
            showEditTask: false,
        })
    }

    connectAccount(dataObject) {
        if (this.canAddConnectedProjects(0)) {
            let state = null;
            switch (dataObject.platform) {
                case 'jira':
                    state = {
                        connectionId: uuidv4(),
                        organisationId: dataObject.organisationId,
                        userEmail: dataObject.userEmail,
                        platform: dataObject.platform,
                        domain: dataObject.domain,
                        platformEmail: dataObject.platformEmail,
                        apiToken: dataObject.apiToken
                    };
                    break;
                case 'trello':
                    state = {
                        platform: dataObject.platform
                    }
                    break;
            }
            this.props.setTokenAndFetchProjects(state);
            this.hideModals()
        } else {
            this.showMaxProjectsSnackbar()
        }
    }

    addProjects(dataObject) {
        if (this.canAddConnectedProjects((dataObject.selectedProjects ? dataObject.selectedProjects.length : 0))) {
            this.props.addProjects(dataObject);
        } else {
            this.showMaxProjectsSnackbar()
        }
    }

    addProjectsBulk(dataObject) {
        if (this.canAddConnectedProjects(dataObject.selectedProjects ? dataObject.selectedProjects.length : 0)) {
            this.props.addProjectsBulk(dataObject);
            this.setState({
                showConnectAccount: false,
                showAddProjects: false,
                showUntrackedProjects: false,
            })
        } else {
            this.showMaxProjectsSnackbar()
        }
    }

    showMaxProjectsSnackbar() {
        this.setState({
            showMaxProjectsAllowedSnackbar: true
        })
    }

    canAddConnectedProjects(newConnectedProjectsCount) {
        let connectedProjects = this.props.projects;

        return (connectedProjects.length + newConnectedProjectsCount) <= MAXIMUM_CONNECTED_PROJECTS
    }

    createProject(dataObject) {}

    showErrorHintSnackbar(errorHintText) {
        this.setState({
            errorHintText
        })
    }

    adjustUserSelection(selectedUserIds) {
        this.setState({
            selectedUserIds
        });
    }

    openInfoBox(anchorEl, issueKey, connectionId, title, description, projectKey, projectId, originalProjectId) {
        this.setState({
            showInfoBox: true,
            currentTaskData: {
                originalProjectId: originalProjectId,
                projectId: projectId,
                title: title,
                description: description,
                issueKey: issueKey,
                connectionId: connectionId,
                anchorEl: anchorEl
            }
        })
    }

    closeInfoBox() {
        this.setState({
            showInfoBox: false,
            currentTaskData: null,
        })
    }

    render() {
        let children = []
        let issuesData = []
        for (let project of this.props.projects) {
            let id = project.id
            if (project) {
                if (project.issues && !project.metaData.disabled && project.issues && Array.isArray(project.issues)) {
                    project.issues.forEach(issue => {
                        issuesData.push({
                            projectId: project.id,
                            originalProjectId: project.originalId,
                            issue: issue,
                            domain: project.metaData.domain,
                            platform: project.metaData.platform,
                            connectionId: project.metaData.connectionId
                        })
                    });
                }

                children.push(
                    <div className='chainLinkContainer'>
                        <ChainLink
                            mainUser={this.props.user}
                            displayName={project.displayName}
                            projectKey={project.key}
                            projectId={id}
                            archiveProject={this.archiveProject.bind(this)}
                            showAddProjects={this.showAddProjects.bind(this)}
                            selected={this.state.selectedProjectId ? project.id === this.state.selectedProjectId : true}
                            connectionId={project.metaData.connectionId}
                            domain={project.metaData.domain}
                            platform={project.metaData.platform}
                            enableProject={this.enableProject.bind(this)}
                            disableProject={this.disableProject.bind(this)}
                            removeProject={this.removeProject.bind(this)}
                            selectProject={this.selectProject.bind(this)}
                            projectMeta={project.metaData}/>
                    </div>
                );
            }
        }

        return (
            <div className="chainContainer">
                {this.props.newProjectsData ?
                    <SingleAccountProjectSelector
                        organisationId={this.props.user ? this.props.user.userData.organisations[0] : null}
                        userEmail={this.props.user ? this.props.user.userEmail : ''}
                        hideModals={this.hideModals.bind(this)}
                        addProjects={this.addProjects.bind(this)}
                        clearNewProjects={this.props.clearNewProjects}
                        newProjectsData={this.props.newProjectsData}/>

                    : <div className="chainContainer">
                        <div className="chainConnectionContainer">
                            {this.props.user && this.props.user.userData.accessLevel === "admin" ? <NewAccountPlaceholder showAddProjects={this.showAddProjects.bind(this)}/> : null }
                            {this.getProjectsOrPlaceholder(this.props.user, children)}
                        </div>
                        {this.state.showConnectAccount ?
                            <ConnectAccount createProject={this.createProject.bind(this)}
                                            platform={this.props.platform}
                                            domain={this.props.domain}
                                            user={this.props.user}
                                            connectAccount={this.connectAccount.bind(this)}
                                            closeConnectAccount={this.closeConnectAccount.bind(this)}/> : null}

                        {this.state.showAddProjects ?
                            <AddProjects
                                hideModals={this.hideModals.bind(this)}
                                showUntrackedProjects={this.showUntrackedProjects.bind(this)}
                                showConnectAccount={this.showConnectAccount.bind(this)}/> : null}

                        {this.state.showUntrackedProjects ?
                            <MultipleAccountProjectSelector selectorType={SelectorType.AddUntrackedProjects} onClose={this.closeUntrackedProjects.bind(this)}
                                      addProjectsBulk={this.addProjectsBulk.bind(this)}
                                      user={this.props.user ? this.props.user : null}/> : null}


                        {this.state.showInfoBox ?
                            <TaskInfo
                                user={this.props.user}
                                usersData={this.props.users[this.state.currentTaskData.projectId]}
                                closeInfobox={this.closeInfoBox.bind(this)}
                                issueKey={this.state.currentTaskData.issueKey}
                                connectionId={this.state.currentTaskData.connectionId}
                                originalProjectId={this.state.currentTaskData.originalProjectId}
                                title={this.state.currentTaskData.title}
                                description={this.state.currentTaskData.description}/> : null}

                        {this.state.showMaxProjectsAllowedSnackbar ? <Snackbar
                            open={true}
                            autoHideDuration={6000}
                            onClose={() => {
                                this.setState({
                                    showMaxProjectsAllowedSnackbar: false
                                })
                            }}
                            message="You've reached the maximum allowed connections for this plan."
                        /> : null}

                        {this.state.errorHintText ? <Snackbar
                            open={true}
                            autoHideDuration={6000}
                            onClose={() => {
                                this.setState({
                                    errorHintText: null
                                })
                            }}
                            message={this.state.errorHintText}
                        /> : null}

                    </div>
                }
                <Tasks
                    openInfoBox={this.openInfoBox.bind(this)}
                    issuesData={issuesData}
                    selectedProjectId={this.state.selectedProjectId}/>
            </div>
        );
    }

    getProjectsOrPlaceholder(user, children) {
        let newChildren = [];

        if (children && Array.isArray(children) && children.length > 0) {
            newChildren.push(children)

        }
        return newChildren;
    }

    selectProject(id) {
        if (id === this.state.selectedProjectId) {
            this.setState({
                selectedProjectId: null,
            });
        } else {
            this.setState({
                selectedProjectId: id,
            });
        }
    }

    disableProject(projectId, connectionId) {
        if (this.props.user) {
            let organisationId = this.props.user.userData.organisations[0]
            let userEmail = this.props.user.userEmail;

            this.props.disableProject({
                organisationId,
                userEmail,
                connectionId,
                projectId
            })
        }
    }

    enableProject(projectId, connectionId) {
        if (this.props.user) {
            let organisationId = this.props.user.userData.organisations[0]
            let userEmail = this.props.user.userEmail;

            this.props.enableProject({
                organisationId,
                userEmail,
                connectionId,
                projectId
            })
        }
    }

    removeProject(projectId, connectionId) {
        if (this.props.user) {
            let organisationId = this.props.user.userData.organisations[0]
            let userEmail = this.props.user.userEmail;

            this.props.removeProject({
                organisationId,
                userEmail,
                connectionId,
                projectId
            })
        }
    }

    archiveProject(projectId) {
        this.props.archiveProject({
            username: this.props.user.userEmail,
            projectId: projectId
        })
    }

    onDragEnd = result => {
        if (result && result.draggableId
            && result.source && result.destination) {
            this.props.changeOrder({
                username: this.props.user.userEmail,
                projectId: result.draggableId,
                sourceIndex: result.source.index,
                destinationIndex: result.destination.index
            });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.userReducer.users,
        loading: state.accountReducer.loading,
        newProjectsData: state.accountReducer.newProjectsData,
        newAccountConnection: state.accountReducer.newAccountConnection,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTokenAndFetchProjects: (dataObject) => dispatch(setTokenAndFetchProjects(dataObject)),
        obtainAuthCode: (dataObject) => window.location.replace(generateAuthCodeUrl(dataObject)),
        disableProject: (dataObject) => dispatch(disableProject(dataObject)),
        enableProject: (dataObject) => dispatch(enableProject(dataObject)),
        removeProject: (dataObject) => dispatch(removeProject(dataObject)),
        addProjects: (dataObject) => dispatch(addProjects(dataObject)),
        addProjectsBulk: (dataObject) => dispatch(addProjectsBulk(dataObject)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Chain)
