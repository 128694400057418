import React from 'react';
import './LoginPicker.css'
import TextButton from "../../../components/button/TextButton";
import {Text} from "../../../components/text/Text";
import Registration from "../../registration/Registration";
import Login from "../Login";
import {GeneralConnector} from "../../../connectors/general/GeneralConnector";

export default class LoginPicker extends React.Component {
    constructor() {
        super();
        this.state = {
            showRegister: false,
            showLogin: false
        }

        this.generalConnector = new GeneralConnector()
    }

    componentDidMount() {
        this.getInvitation()
    }

    async getInvitation() {
        let invitation = this.props.invitationId ? await this.generalConnector.fetchInvitationStatus({
            username: 'unknown_user',
            invitationId: this.props.invitationId
        }) : null;

        if (invitation) {
            this.setState({invitation})
        }
    }

    getComponent() {
        return <Registration invitation={this.state.invitation}/>
        // if (this.state.showRegister) {
        //     return <Registration invitation={this.state.invitation}/>
        // } else if (this.state.showLogin) {
        //     return <Login invitation={this.state.invitation}/>
        // } else {
        //     return <div className='loginPickerContainer'>
        //         <div className='loginPickerLeft'>
        //             <TextButton onClick={() => {
        //                 this.setState({showRegister: true, showLogin: false})
        //             }} outlined>Login with new account</TextButton>
        //         </div>
        //         <div className='loginPickerRight'>
        //             <TextButton onClick={() => {
        //                 this.setState({showLogin: true, showRegister: false})
        //             }} outlined>Login with existing account</TextButton>
        //         </div>
        //     </div>
        // }
    };

    render() {
        return <div className='loginPickerMainContainer'>
            <Text marginBottom={20} marginTop={40} light headline>Invitation</Text>
            {this.getComponent()}
        </div>
    }

}
