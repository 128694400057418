import React from "react";
import "./SingleAccountProjectSelector.css";
import { Modal } from "@material-ui/core";
import { JiraConnector } from "../../connectors/jira/JiraConnector";
import CircularProgress from "@material-ui/core/CircularProgress";
import { StyledButton } from "../button/StyledButton";
import ProjectHolder from "../projectholder/ProjectHolder";

export default class SingleAccountProjectSelector extends React.Component {
  constructor() {
    super();
    this.jiraConnector = new JiraConnector();
    this.state = {
      selectedProjects: [],
    };
  }

  selectProject(projectId, meta) {
    let isIncluded =
      this.state.selectedProjects.find(
        (projectData) => projectData.projectId === projectId
      ) != null;

    let selectedProjects = this.state.selectedProjects;
    if (isIncluded) {
      selectedProjects = selectedProjects.filter(
        (projectData) => projectData.projectId !== projectId
      );
    } else {
      selectedProjects.push({
        projectId: projectId,
        connectionId: meta != null ? meta.connectionId : null,
      });
    }


    this.setState({

      selectedProjects: selectedProjects,
    });
  }

  render() {
      let selectedProjects = this.state.selectedProjects;

      //TODO GO-LIVE
    return (
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={() => {
          //TODO maybe do
        //   this.props.clearNewProjects()
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="projectSelectorMainContainer">
          {this.props.disableTitle ? null : (
            <span className="projectSelectorTitle">Select projects</span>
          )}
          <div className="projectSelectorItemsContainer">
            {this.props.newProjectsData &&
            this.props.newProjectsData.projects ? (
              this.props.newProjectsData.projects.map((project) => {
                let isSelected = selectedProjects.find((projectData) => projectData.projectId === project.id) != null;
                return (
                  <div className="projectItem">
                     <ProjectHolder
                      displayName={project.displayName}
                      selected={isSelected}
                      selectProject={this.selectProject.bind(this)}
                      projectId={project.id}
                      connectionId={project.metaData.connectionId}
                      image={project.metaData.avatarUrl}
                    />
                  </div>
                );
              })
            ) : (
              <CircularProgress style={{ alignSelf: "center" }} />
            )}
          </div>
          <StyledButton
            onClick={() => {
              this.props.hideModals();
              this.props.addProjects({
                connectionId: this.props.newProjectsData
                  ? this.props.newProjectsData.connectionId
                  : null,
                platform: this.props.newProjectsData
                  ? this.props.newProjectsData.platform
                  : null,
                userEmail: this.props.userEmail,
                organisationId: this.props.organisationId,
                selectedProjects: this.state.selectedProjects,
                userId: this.props.userId,
              });
            }}
          >
            Add Projects
          </StyledButton>
        </div>
      </Modal>
    );
  }
}
