import * as React from "react";
import {push, replace} from "connected-react-router";
import {connect} from "react-redux";
import {login, setErrorMessage} from "../../reducers/userReducer";
import "./Login.css"
import StyledTextField from "../../components/textfield/StyledTextField";
import {StyledButton} from "../../components/button/StyledButton";
import TextButton from "../../components/button/TextButton";
import {Text} from "../../components/text/Text";
import * as AWSCognito from "amazon-cognito-identity-js";
import LoginPicker from "./loginpicker/LoginPicker";
import {Logo} from "../homepage/header/logo/Logo";

class Login extends React.Component {

    constructor() {
        super();
        this.state = {
            email: null,
            password: null,
            forgotPassword: false,
            newPassword: false,
            newPasswordOne: null,
            newPasswordTwo: null,
            forgotPasswordVerificationCode: null
        }
    }

    handleKeyDown(event) {
        if (event.key === 'Enter') {
            this.handleLogin();
        }
    }

    setValue(value, type) {
        if (type === 'password') {
            this.setState({
                password: value
            })
        } else if (type === 'email') {
            this.setState({
                email: value
            })
        } else if (type === 'forgotPasswordEmail') {
            this.setState({
                forgotPasswordEmail: value
            })
        } else if (type === 'newPasswordOne') {
            this.setState({
                newPasswordOne: value
            })
        } else if (type === 'newPasswordTwo') {
            this.setState({
                newPasswordTwo: value
            })
        } else if (type === 'forgotPasswordVerificationCode') {
            this.setState({
                forgotPasswordVerificationCode: value
            })
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.errorMessage) {
            setTimeout(() => {
                this.setState({
                    errorMessage: null
                })
                this.props.clearErrorMessage()
            }, 5000)
        }
    }

    render() {
        if (this.props.user) {
            this.props.openApp();
        }

        let content = this.getContent()

        return (
            <div className="loginContainer" onKeyDown={this.handleKeyDown.bind(this)}>
                {content}
            </div>
        );
    }

    async handleLogin() {
        await this.props.login({
            email: this.state.email,
            password: this.state.password,
        });
    }

    getContent() {
        if (this.state.forgotPassword) {
            return this.createForgotPasswordView();

        } else if (this.state.newPassword) {
            return this.createNewPasswordView();

        } else if (this.props.invitationId) {
            return this.createLoginPicker();

        } else {
            return this.createLoginView();
        }
    }

    createLoginView() {
        let loginButtonTopMargin = this.props.errorMessage ? 50 : 80;
        let email = this.props.invitation ? this.props.invitation.receiverEmail : null;

        return <div className="loginFieldsContainer">

            <Logo width={200}/>

            <form className="loginForm" noValidate autoComplete="off">
                <div className={'login-inputs-container'}>
                    <StyledTextField disabled={!!this.props.invitation} placeholder={email}  autoFocus type='email' setValue={this.setValue.bind(this)}/>
                    <StyledTextField type='password' setValue={this.setValue.bind(this)}/>
                    {this.props.errorMessage ? <span className={'error-text-login'}>{this.props.errorMessage}</span> : null}
                </div>
                <div className={'login-footer-container'}>
                    <div className={'login-forgot-password-container'}>
                    <span light onClick={() => {
                        this.setState({forgotPassword: true})
                    }}>Forgot your password?</span>
                    </div>
                    <StyledButton
                        marginBottom={20}
                        marginTop={loginButtonTopMargin}
                        onClick={() => {
                            this.handleLogin()
                        }}>Login</StyledButton>
                    <div className={'login-footer-text-container'}>
                        <span>Need a new account?</span><u onClick={() => {
                        this.props.clearErrorMessage()
                        this.props.openRegister();
                    }}>Sign up</u>
                    </div>
                </div>
            </form>
        </div>
    }

    createForgotPasswordView() {
        let loginButtonTopMargin = this.props.errorMessage ? 50 : 80;
        return <div className="loginFieldsContainer">
            <form className="loginForm" noValidate autoComplete="off">
                <StyledTextField type='forgotPasswordEmail' setValue={this.setValue.bind(this)}/>
                {this.props.errorMessage ? <Text marginTop={10} error>{this.props.errorMessage}</Text> : null}
                {this.state.errorMessage ? <Text marginTop={10} error>{this.state.errorMessage}</Text> : null}

                <StyledButton
                    marginBottom={20}
                    marginTop={loginButtonTopMargin}
                    onClick={() => {
                        this.triggerForgotPasswordFlow()
                    }}>Send</StyledButton>
                <div style={{marginTop: 20, marginBottom: 40, color: "white", textAlign: 'center'}}>
                    <TextButton onClick={() => {
                        //TODO resend code
                    }}><u>Resend code</u></TextButton>
                </div>
            </form>
        </div>
    }

    createNewPasswordView() {
        return <div className="loginFieldsContainer">
            <form className="loginForm" noValidate autoComplete="off">
                <StyledTextField type='forgotPasswordVerificationCode' setValue={this.setValue.bind(this)}/>
                <StyledTextField type='newPasswordOne' setValue={this.setValue.bind(this)}/>
                <StyledTextField type='newPasswordTwo' setValue={this.setValue.bind(this)}/>
                {this.props.errorMessage ? <Text marginTop={10} error>{this.props.errorMessage}</Text> : null}
                {this.state.errorMessage ? <Text marginTop={10} error>{this.state.errorMessage}</Text> : null}

                <StyledButton
                    marginBottom={20}
                    marginTop={loginButtonTopMargin}
                    onClick={() => {
                        this.saveNewPassword()
                    }}>Save</StyledButton>
            </form>
        </div>
    }

    createLoginPicker() {
        return <LoginPicker invitationId={this.props.invitationId} openLogin={this.props.openLogin}
                            openRegister={this.props.openRegister} goBack/>
    }


    onFailure(err) {
        this.setState({
            errorMessage: err.message
        })

        setTimeout(() => {
            this.setState({
                errorMessage: null
            })
        }, 5000)
    }


    async triggerForgotPasswordFlow() {
        const onSuccess = () => {
            this.setState({
                forgotPassword: false,
                newPassword: true
            })
        }

        if (this.state.forgotPasswordEmail) {
            let cognitoUser = this.getCognitoUser();

            cognitoUser.forgotPassword({
                onSuccess: onSuccess.bind(this),
                onFailure: this.onFailure.bind(this)
            });
        } else {
            this.onFailure({message: "Email is missing"})
        }
    }

    async saveNewPassword() {
        if (this.state.forgotPasswordVerificationCode && this.state.newPasswordOne && this.state.newPasswordTwo && (this.state.newPasswordOne === this.state.newPasswordTwo)) {
            let cognitoUser = this.getCognitoUser();

            const onSuccess = () => {
                this.props.openApp();
            }

            cognitoUser.confirmPassword(this.state.forgotPasswordVerificationCode, this.state.newPasswordOne, {
                onFailure: this.onFailure.bind(this),
                onSuccess: onSuccess.bind(this)
            });
        }
    }

    confirmPassword() {
        let cognitoUser = this.getCognitoUser();

        return new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(this.state.forgotPasswordVerificationCode, this.state.newPasswordOne, {
                onFailure(err) {
                    reject(err);
                },
                onSuccess() {
                    resolve();
                },
            });
        });
    }

    getCognitoUser() {
        const poolData = {UserPoolId: 'eu-central-1_NDa3AfY6g', ClientId: 'acjkkh99mm6uq0caqni21qav'};
        const userPool = new AWSCognito.CognitoUserPool(poolData);

        return new AWSCognito.CognitoUser({
            Username: this.state.forgotPasswordEmail,
            Pool: userPool
        });
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.currentUser,
        errorMessage: state.userReducer.errorMessage
    }
};

const mapDispatchToProps = dispatch => {
    return {
        openApp: () => dispatch(replace('/app')),
        openRegister: () => dispatch(push('/registration')),
        login: (loginData) => dispatch(login(loginData)),
        openLogin: () => dispatch(push('/login')),
        clearErrorMessage: () => dispatch(setErrorMessage(null))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)
