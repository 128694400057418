import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

export default class NewAccountPlaceholder extends React.Component {
  render() {
    return (
        <div onClick={this.props.onClick}>
            <Tooltip title="connect account">
                <IconButton>
                    <AddIcon
                        style={{
                            width: 30,
                            height: 30,
                            alignSelf: "center",
                            cursor: "pointer",
                            marginRight: "40px",
                        }}
                        onClick={() => {
                            //TODO add from existing projects
                            this.props.showAddProjects(this.props.user)
                        }}
                        className="chainLinkPlaceholder"
                    />
                </IconButton>
            </Tooltip>
        </div>
    );
  }
}
