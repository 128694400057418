import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {JiraConnector} from "../connectors/jira/JiraConnector";
import React from "react";
import {TaskchainConnector} from "../connectors/taskchain/TaskchainConnector";
import {GeneralConnector} from "../connectors/general/GeneralConnector";
import {TrelloConnector} from "../connectors/trello/TrelloConnector";

const prefix = 'accounts';
const jiraConnector = new JiraConnector();
const trelloConnector = new TrelloConnector();
const generalConnector = new GeneralConnector();
const taskchainConnector = new TaskchainConnector();

export const fetchProjects = createAsyncThunk(`${prefix}/fetchProjects`, async (user, thunkAPI) => {
    let data = [];
    if (user) {
        data = await generalConnector.fetchProjectData(user.userEmail, user.userData.organisations[0]);
    }

    console.log(`Fetch project data!!!!!!: `, data)
    let projects = []
    for (let item of data) {
        projects.push(...item.projects);
    }

    return {
        projects: projects
    };
});

export const fetchDisconnectedProjects = createAsyncThunk(`${prefix}/fetchProjects`, async (user, thunkAPI) => {
    let data = [];
    if (user) {
        data = await generalConnector.fetchDisconnectedProjectsData(user);
    }

    let projects = []
    for (let item of data) {
        projects.push(...item.projects);
    }

    return {
        projects: projects
    };
});

export const disableProject = createAsyncThunk(`${prefix}/disableProject`, async (dataObject, thunkAPI) => {
    let disabledProjectId = await generalConnector.disableProject(dataObject);

    return {
        disabledProjectId
    };
});

export const enableProject = createAsyncThunk(`${prefix}/enableProject`, async (dataObject, thunkAPI) => {
    let enabledProjectId = await generalConnector.enableProject(dataObject);

    return {
        enabledProjectId
    };
});

export const removeProject = createAsyncThunk(`${prefix}/removeProject`, async (dataObject, thunkAPI) => {
    let removedProjectId = await generalConnector.removeProject(dataObject);

    return {
        removedProjectId
    };
});

export const addProjects = createAsyncThunk(`${prefix}/addProjects`, async (dataObject, thunkAPI) => {
    let data = {};
    switch (dataObject.platform) {
        case 'jira': {
            data = await jiraConnector.addProjects(dataObject);
            break;
        }
        case 'trello': {
            data = await trelloConnector.addProjects(dataObject);
            break;
        }
        case 'taskchain': {

            break;
        }
        case 'asana': {

            break;
        }
    }

    return {
        projects: data ? data : []
    };
});

export const addProjectsBulk = createAsyncThunk(`${prefix}/addProjectsBulk`, async (dataObject, thunkAPI) => {
    let data = await generalConnector.addProjectsBulk(dataObject);

    let projects = []
    for (let item of data) {
        projects.push(...item.projects);
    }

    return {
        projects
    };
});

export const addProjectsToUser = createAsyncThunk(`${prefix}/addProjectsToUser`, async (dataObject, thunkAPI) => {
    let data = await generalConnector.addProjectsToUser(dataObject);

    let projects = []
    for (let item of data) {
        projects.push(...item.projects);
    }

    return {
        projects
    };
});

export const removeProjectFromUser = createAsyncThunk(`${prefix}/addProjectsToUser`, async (dataObject, thunkAPI) => {
    let data = await generalConnector.removeProjectFromUser(dataObject);

    let projects = []
    for (let item of data) {
        projects.push(...item.projects);
    }

    return {
        projects
    };
});

export const setTokenAndFetchProjects = createAsyncThunk(`${prefix}/setTokenAndFetchProjects`, async (dataObject, thunkAPI) => {
    let projectsData = null;
    switch (dataObject.platform) {
        case 'jira': {
            projectsData = await jiraConnector.setTokenAndFetchProjects(dataObject)
            break;
        }
        case 'trello':
            projectsData = await trelloConnector.setTokenAndFetchProjects(dataObject)
            break;
    }

    return {
        newProjectsData: projectsData
    };
});

export const createProject = createAsyncThunk(`${prefix}/createProject`, async (dataObject, thunkAPI) => {
    let newProject = await taskchainConnector.createProject(dataObject);
    return {
        newProject: newProject
    };
});

function moveInArray(arr, fromIndex, toIndex) {
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr
}

export const setLoading = createAsyncThunk(`${prefix}/setLoading`, async (isLoading, thunkAPI) => {
    return {
        loading: isLoading
    }
});

const accountSlice = createSlice({
    name: prefix,
    initialState: {
        loading: true,
        projects: [],
        bookmarkedIssues: [],
        timeEstimates: [],
        newProjectsData: null, //this is null to dif. from empty response []. On empty response - other logic
        newAccountConnection: null,
        sortingMode: null
    },
    extraReducers: {
        [fetchProjects.fulfilled]: (state, action) => {
            state.loading = false;
            state.projects = action.payload.projects;
        },
        [fetchProjects.rejected]: (state, action) => {
            //todo handle
        },
        [disableProject.fulfilled]: (state, action) => {
            let project = state.projects.find(project => project.id === action.payload.disabledProjectId);
            if (project) {
                project.metaData.disabled = true;
            }
        },
        [disableProject.rejected]: (state, action) => {
            //todo handle
        },
        [removeProject.fulfilled]: (state, action) => {
            state.projects = state.projects.filter(project => project.id !== action.payload.removedProjectId);
        },
        [removeProject.rejected]: (state, action) => {
            //todo handle
        },
        [enableProject.fulfilled]: (state, action) => {
            let project = state.projects.find(project => project.id === action.payload.enabledProjectId);
            if (project) {
                project.metaData.disabled = false;
            }
        },
        [enableProject.rejected]: (state, action) => {
            //todo handle
        },
        [addProjects.fulfilled]: (state, action) => {
            state.projects = action.payload.projects;
            state.newProjectsData = null;
        },
        [addProjects.rejected]: (state, action) => {
        },
        [addProjectsBulk.fulfilled]: (state, action) => {
            state.projects = action.payload.projects;
            state.newProjectsData = null;
        },
        [addProjectsBulk.rejected]: (state, action) => {
        },
        [createProject.fulfilled]: (state, action) => {
            state.projects.push(action.payload.newProject);
        },
        [createProject.rejected]: (state, action) => {
        },
        [setTokenAndFetchProjects.fulfilled]: (state, action) => {
            state.newProjectsData = action.payload.newProjectsData;
        },
        [setTokenAndFetchProjects.rejected]: (state, action) => {
            //todo handle
        },
        [setLoading.fulfilled]: (state, action) => {
            state.loading = action.payload.loading
        },
        [setLoading.rejected]: (state, action) => {
            //todo handle
        },
        [addProjectsToUser.fulfilled]: (state, action) => {
            const email = action.payload.userEmail

        },
        [addProjectsToUser.rejected]: (state, action) => {
            //todo handle
        },
        [removeProjectFromUser.fulfilled]: (state, action) => {
            const email = action.payload.userEmail

        },
        [removeProjectFromUser.rejected]: (state, action) => {
            //todo handle
        }
    }
});

function getNewComments(issueKey, issueComments, lastSeenDate) {
    let issueCommentData = issueComments.find(issueCommentsData => issueCommentsData.issueKey === issueKey);
    if (issueCommentData) {
        return issueCommentData.comments.filter(comment => {
            let createDate = new Date(comment.created)
            return createDate > lastSeenDate;
        });
    }
    return [];
}

export const accountReducer = accountSlice.reducer;
