import React from 'react'
import './ConnectAccount.css'
import {Modal} from "@material-ui/core";
import {ReactComponent as Jira} from '../../../images/logo_jira.svg'
import {ReactComponent as Trello} from '../../../images/logo_trello.svg'
import StyledTextField from "../../textfield/StyledTextField";
import {StyledButton} from "../../button/StyledButton";
import TextButton from "../../button/TextButton";
import Carousel from 'react-material-ui-carousel'


export default class ConnectAccount extends React.Component {

    constructor() {
        super();

        this.state = {
            currentPosition: 0,
            selectedPlatform: 'jira',
            email: null,
            password: null,
            apiToken: ""
        }
    }

    setValue(value, type) {
        if (type === 'domain') {
            this.setState({
                domain: value
            })
        } else if (type === 'projectName') {
            this.setState({
                projectName: value
            })
        } else if (type === 'email') {
            this.setState({
                platformEmail: value
            })
        } else if (type === 'apiToken') {
            this.setState({
                apiToken: value
            })
        }
    }

    createJiraView() {
        return <div className='platformViewItems'>
            <StyledTextField label='Domain' type='domain' setValue={this.setValue.bind(this)}/>
            <StyledTextField label='Email' type='email' setValue={this.setValue.bind(this)}/>
            <StyledTextField label='Api-Token' type='apiToken' setValue={this.setValue.bind(this)}/>
            <a style={{color: "white", textDecoration: 'none', marginBottom:30}} target="_blank" href={"https://id.atlassian.com/manage-profile/security/api-tokens"}>generate api token</a>
            <StyledButton marginBottom={20} marginTop={40} onClick={() => {
                this.props.connectAccount({
                    organisationId: this.props.user.userData.organisations[0],
                    userEmail: this.props.user.userEmail,
                    platform: this.state.selectedPlatform,
                    platformEmail: this.state.platformEmail,
                    apiToken: this.state.apiToken,
                    domain: this.state.domain
                });
            }}>Connect</StyledButton>

            <button className={'close-button'} onClick={() => {
                this.props.closeConnectAccount()
            }}>Close</button>
        </div>
    }

    createTaskchainView() {
        return <div className='platformViewItems'>
            <StyledTextField label='Project name' type='projectName' setValue={this.setValue.bind(this)}/>
            <StyledButton marginBottom={20} marginTop={40} onClick={() => {
                if (!this.state.projectName) {
                    alert('missing project name');
                } else {
                    this.props.createProject({
                        username: this.props.user.userEmail,
                        platform: this.state.selectedPlatform,
                        data: {
                            projectName: this.state.projectName,
                        }
                    });
                }
            }}>Create</StyledButton>

            <TextButton marginBottom={20} marginTop={20} onClick={() => {
                this.props.closeConnectAccount()
            }}>Close</TextButton>
        </div>
    }

    createTrelloView() {
        return <div className='platformViewItems'>
            <StyledButton marginBottom={20} marginTop={40} onClick={() => {
                this.props.connectAccount({
                    username: this.props.user.userEmail,
                    platform: this.state.selectedPlatform,
                });

            }}>Connect</StyledButton>

            <TextButton marginBottom={20} marginTop={20} onClick={() => {
                this.props.closeConnectAccount()
            }}>Close</TextButton>
        </div>
    }

    createEmptyView() {
        return <div className='platformViewItems'>
            <span className='comingSoonText'>Coming soon!</span>

            <TextButton marginBottom={20} marginTop={20} onClick={() => {
                this.props.closeConnectAccount()
            }}>Close</TextButton>
        </div>
    }

    getPlatformView() {
        switch (this.state.currentPosition) {
            case 0:
                return this.createJiraView()
            case 1:
                return this.createTrelloView()
        }
    }

    getSelectedPlatform(position) {
        switch (position) {
            case 0:
                return 'jira'
            case 1:
                return 'trello'
        }
    }

    render() {
        return (
            <Modal
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                open={open}
                onClose={() => this.props.closeConnectAccount()}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">

                <div className="createAccountContainer">
                    <Carousel
                        onChange={(currentPosition) => {
                            this.setState({
                                selectedPlatform: this.getSelectedPlatform(currentPosition),
                                currentPosition: currentPosition
                            })
                        }}
                        swipe={true}
                        animation={null}
                        autoPlay={false}
                        navButtonsAlwaysVisible={true}
                        className='createAccountContainer'>
                        <Jira className='singleService'/>
                        <Trello className='singleService'/>
                    </Carousel>
                    {this.getPlatformView()}
                </div>
            </Modal>
        )
    }
}
