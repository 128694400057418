import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Text} from "../../../../components/text/Text";
import './HomePageHeaderContainer.css'
import MenuIcon from '@material-ui/icons/Menu';

export const useStyles = makeStyles(() => ({
    menuPaper: {
        backgroundColor: "#334756"
    }
}));


export default function HomePageHeaderMenu(props) {
    let classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const openPricing = async () => {
        props.openPricing()
    };

    const openBlog = async () => {
        props.openBlog()
    };

    const openPrivacy = async () => {
        props.openPrivacy()
    };

    const openHome = () => {
        props.openHome()
    };

    const openApp = () => {
        props.openApp()
    };

    const handleClose =() => {
        setAnchorEl(null);
    }


    return (

        <div className='headerMenuContainer'>
            <div onClick={handleClick} style={{color: 'black', width: 30, height: 30, display:'flex', alignItems:'center', justifyContent: 'center' }}>
                <MenuIcon/>
            </div>
            <Menu
                classes={{ paper: classes.menuPaper }}
                style={{marginTop:35}}
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem style={{color:'white', opacity: 0.8}} onClick={openHome}>Home</MenuItem>
                <MenuItem style={{color:'white', opacity: 0.8}} onClick={openPricing}>Pricing</MenuItem>
                <MenuItem style={{color:'white', opacity: 0.8}} onClick={openBlog}>Blog</MenuItem>
                <MenuItem style={{color:'white', opacity: 0.8}} onClick={openApp}>App</MenuItem>
            </Menu>
        </div>
    );
}