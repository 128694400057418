import React from "react";
import {connect} from "react-redux";
import {getTestUsers} from "../../../homepage/demo/data/TestData";
import {Text} from "../../../../components/text/Text";
import './TaskMobile.css'
import {getPlatformLogo} from "../../../../util/utils";
import {getIssueUniqueId} from "../../../../util/idUtils";
import Avatar from "@material-ui/core/Avatar";
import {Chat} from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";

class TaskMobile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width: screen.width,
            height: screen.height
        }
    }

    render() {
        let issue = this.props.issueData.issue;
        let assigneeAvatarUrl = issue.fields.assignee ? issue.fields.assignee.avatarUrl : null;
        let assigneeAvatarDisplayName = issue.fields.assignee ? issue.fields.assignee.displayName : null;
        let domain = this.props.issueData.domain;
        let connectionId = this.props.issueData.connectionId;
        let platform = this.props.issueData.platform;
        let title = `${issue.displayName} - ${issue.fields.summary}`;
        let description = `${issue.fields.description}`
        let projectKey = issue.fields.project.key;
        let projectId = this.props.issueData.projectId;
        let originalProjectId = this.props.issueData.originalProjectId

        return <div className='taskMobileMainContainer'>
            <div className='taskMobileContainer'>
                <div className='taskMobileTopContainer'>
                    <Text textAlign='left' light fontSize={14}>{title}</Text>
                </div>
                <div className='taskMobileBottomContainer'>
                    <div className='taskMobileBottomContainerLeft'>
                        {getPlatformLogo(platform)}
                        <Text light fontSize={12}>{domain}</Text>
                    </div>
                    <div style={{display: "flex"}}>
                        <Text light fontSize={18} marginRight={10}>Status:</Text>
                        <Text light border paddingLeft={20} paddingRight={20} paddingTop={5}
                              paddingBottom={5}>{issue.fields.status.name}</Text>
                        {assigneeAvatarUrl ? <div style={{alignSelf: 'center', marginLeft: 10}}>
                            <Tooltip title={`Assigned to ${assigneeAvatarDisplayName}`}>
                                <Avatar alt={assigneeAvatarDisplayName} src={assigneeAvatarUrl}/>
                            </Tooltip>
                        </div> : null}
                        <Tooltip title="open chat">
                            <Chat style={{color: "white", cursor: 'pointer', alignSelf: 'center'}} onClick={() => {
                                this.props.openInfoBox(null, issue.key, connectionId, title, description, projectKey, projectId, originalProjectId);
                            }}/>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state, ownProps) => {
    let currentUsers = ownProps.testMode ? getTestUsers() : state.userReducer.users[ownProps.projectId];
    return {
        currentAssignableUsers: currentUsers ? currentUsers.users : []
    }
};

export default connect(
    mapStateToProps,
    null
)(TaskMobile)
