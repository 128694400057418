import {TextField} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import PersonIcon from '@material-ui/icons/AccountCircle';
import VrpKeyIcon from '@material-ui/icons/VpnKey';
import DriveFileRenameOutline from '@material-ui/icons/InsertDriveFile';
import Domain from '@material-ui/icons/Domain';
import VpnKey from '@material-ui/icons/VpnKey';

import * as React from "react";


export default class StyledTextField extends React.Component {

    getType() {
        if (this.props.type.includes('password')) {
            return 'password';
        }

        return this.props.type;
    }

    render() {
        let height = this.props.multiline ? 'auto' : 35;
        let minHeight = this.props.multiline ? 200 : 35;
        return <TextField
            disabled={this.props.disabled}
            autoFocus={this.props.autoFocus}
            multiline={this.props.multiline ? this.props.multiline : false}
            maxRows={this.props.maxRows ? this.props.maxRows : 1}
            placeholder={this.props.label ? this.props.label : this.getPlaceholderText()}
            style={{
                minHeight: minHeight,
                maxHeight: 200,
                height: height,
                width: this.props.width ? this.props.width : '80%',
                maxWidth: 300,
                backgroundColor: "white",
                borderRadius: 5,
                alignSelf: this.props.alignSelf ? this.props.alignSelf : 'left'
            }}
            onChange={(event) => {
                this.props.setValue(event.target.value, this.props.type);
            }}
            FormHelperTextProps={{
                style: {
                    color: 'transparent'
                }
            }}
            type={this.getType()}
            InputProps={{
                disableUnderline: true,
                startAdornment: (
                    <InputAdornment style={{color: '',borderRadius: 24,}}>
                        {this.props.hideIcon ? null : this.getIcon()}
                    </InputAdornment>
                )
            }}
        />
    }


    getIcon() {
        if (this.props.type === 'email' | this.props.type.toLowerCase().includes('email')) {
            return <EmailIcon style={{color: '#594674'}}/>
        } else if (this.props.type === 'password' | this.props.type === 'newPasswordOne' | this.props.type === 'newPasswordTwo') {
            return <LockIcon style={{color: '#594674'}}/>
        } else if (this.props.type === 'name') {
            return <PersonIcon style={{color: '#594674'}}/>
        } else if (this.props.type === 'forgotPasswordVerificationCode') {
            return <VrpKeyIcon style={{color: '#594674'}}/>
        } else if (this.props.type === 'projectName') {
            return <DriveFileRenameOutline style={{color: '#594674'}}/>
        } else if (this.props.type === 'apiToken') {
            return <VpnKey style={{color: '#594674'}}/>
        } else if (this.props.type === 'domain') {
            return <Domain style={{color: '#594674'}}/>
        }
    }

    getPlaceholderText() {
        if(this.props.placeholder) return this.props.placeholder;

        if (this.props.type === 'email' | this.props.type === 'forgotPasswordEmail') {
            return "Email"
        } else if (this.props.type === 'password') {
            return "Password"
        } else if (this.props.type === 'name') {
            return "Name"
        } else if (this.props.type === 'newPasswordOne') {
            return "New Password"
        } else if (this.props.type === 'newPasswordTwo') {
            return "Confirm Password"
        } else if (this.props.type === 'forgotPasswordVerificationCode') {
            return "Verification Code"
        } else {
            if (this.props.type) {
                return this.props.type.charAt(0).toUpperCase() + this.props.type.substring(1);

            }

            return '';
        }
    }
}
