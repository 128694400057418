import React from 'react'
import '../HomePage.css'
import {Demo} from "../demo/Demo";
import {StyledButton} from "../../../components/button/StyledButton";
import {HeadlineText} from "../../../components/headlinetext/HeadlineText";
import {SecondaryText} from "../../../components/secondarytext/SecondaryText";
import Partner from "../../../components/partners/Partners";
import {Text} from "../../../components/text/Text";
import Benefits from "../../../components/benefits/Benefits";

export class HomePageLanding extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <div className='homePageHomeContainer'>
            <HeadlineText>You shouldn't waste your time switching accounts and platforms</HeadlineText>
            <SecondaryText>Hassle-free cross-platform task planner for busy developers.</SecondaryText>
            <StyledButton
                marginBottom={20}
                onClick={() => {
                this.props.openPricing()
            }}>Get Started</StyledButton>
            <Partner/>
            <Demo/>
        </div>;
    }
}