import React from "react";
import './MultipleAccountsProjectsSelector.css'
import {JiraConnector} from "../../connectors/jira/JiraConnector";
import {CircularProgress, Modal} from "@material-ui/core";
import TextButton from "../button/TextButton";
import ProjectHolder from "../projectholder/ProjectHolder";
import {Text} from "../text/Text";
import {StyledButton} from "../button/StyledButton";
import {getPlatformLogo} from "../../util/utils";
import {GeneralConnector} from "../../connectors/general/GeneralConnector";


export const SelectorType = {
    AddUntrackedProjects: 'add_untracked_projects', //for adding the projects, which are not connected to the admin account yet.
    AddExistingProjects: 'add_existing_projects', //for adding projects in the Users component. Only the currently available projects can be added
};

export default class MultipleAccountProjectSelector extends React.Component {

    constructor(props) {
        super(props);
        this.generalConnector = new GeneralConnector();
        this.state = {
            orderedProjectsData: props.orderedProjectsData ? this.props.orderedProjectsData : [],
            selectedProjects: []
        }
    }

    componentDidMount() {
        //this.state.orderedProjectsData is null only the first time
        if (this.props.selectorType === SelectorType.AddUntrackedProjects) {
            this.fetchUntrackedProjects();
        }
    }

    async fetchUntrackedProjects() {
        let orderedProjectsData = [];
        let untrackedProjects = await this.generalConnector.fetchDisconnectedProjectsData(this.props.user);
        if (untrackedProjects && Array.isArray(untrackedProjects)) {
            for (let i = 0; i < untrackedProjects.length; i++) {
                let projectData = untrackedProjects[i];
                for (let project of projectData.projects) {

                    let domain = project.metaData.domain;
                    let platform = project.metaData.platform;
                    let id = project.metaData.connectionId;

                    let item = orderedProjectsData.find(item => item.id === id);
                    if (item) {
                        let projects = item.projects;
                        if (projects && Array.isArray(projects)) {
                            projects.push(project);
                        }
                    } else {
                        item = {
                            id: id,
                            domain: domain,
                            platform: platform,
                            projects: [project]
                        }
                        orderedProjectsData.push(item);
                    }
                }
            }

            this.setState({
                orderedProjectsData: orderedProjectsData
            })
        }
    }

    selectProject(projectId, metaData) {
        let isIncluded = this.state.selectedProjects.find(projectData => projectData.projectId === projectId) != null

        let selectedProjects = this.state.selectedProjects;
        if (isIncluded) {
            selectedProjects = selectedProjects.filter(projectData => projectData.projectId !== projectId);
        } else {
            selectedProjects.push({
                connectionId: metaData ? metaData.connectionId : null,
                platform: metaData ? metaData.platform : null,
                projectId: projectId
            })
        }
        this.setState({
            selectedProjects: selectedProjects
        })
    }

    render() {
        return (
            <Modal
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                open={open}
                onClose={() => {
                    this.props.onClose()
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <div className='projectsMainContainer'>
                    {this.state.orderedProjectsData && this.state.orderedProjectsData.length > 0 ?
                        this.state.orderedProjectsData.map(projectsData => {
                            return <div className='projectsContentContainer'>

                                <div className='projectsContentTitleContainer'>
                                    {getPlatformLogo(projectsData.platform)}
                                    <Text light fontSize={19}>{projectsData.domain}</Text>
                                </div>

                                <div className='projectsContainer'>
                                    {projectsData.projects.map(project => {
                                        let projectMeta = project.metaData;
                                        let isSelected = this.state.selectedProjects.find(projectData => project.id === projectData.projectId) != null;
                                        return <div className='projectItem'>
                                            <ProjectHolder
                                                selected={isSelected}
                                                connectionId={project.metaData.connectionId}
                                                selectProject={this.selectProject.bind(this)}
                                                projectId={project.id}
                                                projectKey={project.key}
                                                displayName={project.displayName}
                                                image={projectMeta.avatarUrl ? projectMeta.avatarUrl : projectMeta.color}
                                            />
                                        </div>;
                                    })}
                                </div>
                            </div>
                        })

                        : <div style={{marginTop: 20, flexDirection: 'column', display: 'flex', width: '100%', justifyContent: 'center'}}>
                            <CircularProgress style={{marginTop: 10, marginBottom: 20, alignSelf: "center", color: "white", width: 20, height: 20}}/>
                            <Text light>Checking for projects</Text>
                    </div>}

                    <div className='buttonArea'>
                        <StyledButton marginBottom={0} marginTop={40} onClick={() => {
                            if (this.props.selectorType === SelectorType.AddUntrackedProjects) {
                                this.props.addProjectsBulk({
                                    user: this.props.user ? this.props.user : null,
                                    selectedProjects: this.state.selectedProjects
                                });
                            }else {
                                this.props.addProjectsToUser({
                                    user: this.props.user ? this.props.user : null,
                                    selectedProjects: this.state.selectedProjects
                                })
                            }
                        }}>Add Projects</StyledButton>

                        <TextButton
                            fontSize={16}
                            marginTop={20}
                            onClick={() => {
                                this.props.onClose()
                            }}>Close</TextButton>
                    </div>

                </div>
            </Modal>
        );
    }
}
