import React from "react";
import { connect } from "react-redux";
import {BLOG, HOME, HomePageHeader, PRICING} from "./header/HomePageHeader";
import { getCurrentUser } from "../../reducers/userReducer";
import { push } from "connected-react-router";
import "./HomePage.css";
import { HomePagePricing } from "./pricing/HomePagePricing";
import { HomePageLanding } from "./landing/HomePageLanding";
import qs from "qs";
import { PrivacyPage } from "../../components/legal/privacy/PrivacyPage";
import Footer from "../../components/footer/Footer";
import { TermsAndConditions } from "../../components/legal/termsandconditions/TermsAndConditions";
import { CookiePolicy } from "../../components/legal/cookies/CookiePolicy";
import { CookieComponent } from "../../components/cookiecomponent/CookieComponent";
import { Typography } from "@material-ui/core";
import FAQ from "../../components/faq/FAQ";
import { IntroductionPage } from "./introduction/IntroductionPage";
import Blog from "../blog/Blog";

class HomePage extends React.Component {
  constructor() {
    super();

    this.state = {
      showPricing: false,
      width: screen.width,
    };
  }

  componentDidMount() {
    this.props.getCurrentUser();
    this.checkPaymentStatus();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: screen.width });
  };

  checkPaymentStatus() {
    if (this.props.paymentSuccess) {
      window.location.replace(`${location.protocol.concat("//").concat(window.location.host)}/app`);
    } else if (this.props.paymentCanceled) {
      //TODO add snackbar for payment failed. Maybe directly in /app
    }
  }

  getComponent() {
    if (this.props.showPricing) {
      return (
        <HomePagePricing
          headerText="Maximize efficiency and minimize errors."
          descriptionText="Get access to all integrations, future updates and premium support."
          actionOne={this.props.openRegistration}
        />
      );
    } else if (this.props.showTermsAndConditions) {
      return <TermsAndConditions />;
    } else if (this.props.showCookiePolicy) {
      return <CookiePolicy />;
    } else if (this.props.showPrivacy) {
      return <PrivacyPage />;
    } else if (this.props.showMobilePrivacy) {
      return <PrivacyPage mobile/>;
    } else if (this.props.showBlog) {
      return <Blog blogId={this.props.blogId}/>;
    } else {

      return <IntroductionPage openRegistration={this.props.openRegistration}/>;
    }
  }

  getSelectedOption() {
    if (this.props.showPricing) {
      return PRICING
    }else if (this.props.showBlog) {
      return BLOG;
    }else if (this.props.showHome) {
      return HOME;
    }else if (this.props.showPricing) {
      return PRICING;
    }else {
      return HOME;
    }
  }

  render() {
    return (
      <div className="homePageMainContainer">
        <HomePageHeader
          selectedOption={this.getSelectedOption()}
          isLoggedIn={this.props.user != null}
          openPrivacy={this.props.openPrivacy}
          openApp={this.props.openApp}
          openBlog={this.props.openBlog}
          showPricing={this.props.openPricing}
          showHome={this.props.openHome}
        />
        {this.getComponent()}
        <FAQ/>
        <Footer
          openPrivacy={this.props.openPrivacy}
          openCookiePolicy={this.props.openCookiePolicy}
          openTermsAndConditions={this.props.openTermsAndConditions}
        />

        <CookieComponent openCookiePolicy={this.props.openCookiePolicy} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openApp: () => dispatch(push("/app")),
    openPricing: () => dispatch(push("/pricing")),
    openBlog: () => dispatch(push("/blog")),
    openPrivacy: () => dispatch(push("/privacy")),
    openCookiePolicy: () => dispatch(push("/cookie-policy")),
    openTermsAndConditions: () => dispatch(push("/terms-and-conditions")),
    openHome: () => dispatch(push("/")),
    openRegistration: () => dispatch(push("/registration")),
    getCurrentUser: () => dispatch(getCurrentUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
