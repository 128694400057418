import {Component} from "react/cjs/react.production.min";
import React from "react";
import ChainLink from "../../../../components/chainlink/ChainLink";
import Tasks from "../../../tasks/Tasks";
import TaskInfo from "../../../../components/comments/TaskInfo";
import {ORDER_BY_PRIORITY} from "../../../../Constants";
import './DemoChain.css'
import {moveInArray} from "../../../../util/utils";
import {getTestProjects, getTestUsers} from "../data/TestData";
import {connect} from "react-redux";
import Benefits from "../../../../components/benefits/Benefits";
import {Snackbar} from "@material-ui/core";

class DemoChain extends Component {

    constructor() {
        super();
        this.state = {
            showInfoBox: false,
            showConnectAccount: false,
            commentsAnchorEl: null,
            currentIssueKey: null,
            sortingMode: ORDER_BY_PRIORITY,
            projects: getTestProjects(),
            bookmarkedIssues: [],
        }
    }

    showConnectAccount() {
        this.setState({
            showConnectAccount: true
        })
    }

    closeConnectAccount() {
        this.setState({
            showConnectAccount: false
        })
    }

    removeTestTask(removeTaskData) {
        let newProjects = [];
        this.state.projects.forEach(project => {
            if (project.id === removeTaskData.projectId) {
                if (project.issues) {
                    project.issues = project.issues.filter(issue => issue.key !== removeTaskData.issueKey);
                    newProjects.push(project);
                }
            } else {
                newProjects.push(project);
            }
        })

        this.setState({
            projects: newProjects
        })
    }

    handleTimeTracker(showOrHide, timerData) {
        this.setState({
            showTimeTracker: showOrHide
        })
    }


    toggleBookmark(dataObject) {
        let bookmarkedIssues = this.state.bookmarkedIssues;
        if (bookmarkedIssues.find(issueId => issueId === dataObject.issueId)) {
            bookmarkedIssues = bookmarkedIssues.filter(issueId => issueId !== dataObject.issueId);
        } else {
            bookmarkedIssues.push(dataObject.issueId);
        }

        this.setState({bookmarkedIssues})
    }

    render() {
        let children = [];
        let issuesData = []
        let index = 0;
        for (let project of this.state.projects) {
            if (project.issues && !project.metaData.disabled) {
                project.issues.forEach(issue => {
                    issuesData.push({
                        projectId: project.id,
                        issue: issue,
                        domain: project.metaData.domain,
                        platform: project.metaData.platform
                    })
                });
            }

            let id = project.id

            if (project) {
                children.push(
                    <div
                        className='demoChainLinkContainer'>
                        <ChainLink key={id}
                                   displayName={project.displayName}
                                   projectKey={project.key}
                                   projectId={id}
                                   selected={this.state.selectedProjectId ? project.id === this.state.selectedProjectId : true}
                                   domain={project.metaData.domain}
                                   platform={project.metaData.platform}
                                   enableProject={this.enableProject.bind(this)}
                                   disableProject={this.disableProject.bind(this)}
                                   removeProject={this.removeProject.bind(this)}
                                   selectProject={this.selectProject.bind(this)}
                                   projectStateMeta={project.metaData}
                                   projectMeta={project.projectMeta}

                        />
                    </div>
                );
            }
            index++
        }

        return (
            <div className="demoChainContainer">
                {this.state.errorHintText ? <Snackbar
                    open={true}
                    autoHideDuration={6000}
                    onClose={() => {
                        this.setState({
                            errorHintText: null
                        })
                    }}
                    message={this.state.errorHintText}
                /> : null}
                <div
                    className="demoChainConnectionContainer">
                    {children}
                </div>
                <Tasks testMode
                       toggleBookmark={this.toggleBookmark.bind(this)}
                       bookmarkedIssues={this.state.bookmarkedIssues}
                       showErrorHintSnackbar={this.showErrorHintSnackbar.bind(this)}
                       currentlyTrackedIssue={this.state.trackedIssueData && this.state.trackedIssueData.issueData ? this.state.trackedIssueData.issueData.issue : null}
                       showOrHideTimeTracker={this.showOrHideTimeTracker.bind(this)}
                       updateTimeTracker={this.updateTimeTracker.bind(this)}
                       timeEstimates={this.props.timeEstimates}
                       removeTestTask={this.removeTestTask.bind(this)}
                       openInfoBox={this.openInfoBox.bind(this)}
                       sortingMode={this.state.sortingMode}
                       setSortingMode={this.setSortingMode.bind(this)}
                       issuesData={issuesData}
                       selectedProjectId={this.state.selectedProjectId}/>
                {this.state.showInfoBox ?
                    <TaskInfo
                        testMode
                        usersData={getTestUsers()}
                        username={'test'}
                        closeInfobox={this.closeInfoBox.bind(this)}
                        issueKey={this.state.currentTaskData.issueKey}
                        platform={this.state.currentTaskData.platform} domain={this.state.currentTaskData.domain}
                        title={this.state.currentTaskData.title}
                        description={this.state.currentTaskData.description}/> : null}

                <Benefits/>
            </div>
        );
    }

    selectProject(id) {
        if (id === this.state.selectedProjectId) {
            this.setState({
                selectedProjectId: null
            });
        } else {
            this.setState({
                selectedProjectId: id
            });
        }
    }

    disableProject(platform, domain, projectId) {
        let projects = this.state.projects;
        let project = projects.find(project => project.id === projectId);
        if (project) {
            project.metaData.disabled = true;
        }
        this.setState({
            projects
        })
    }

    enableProject(platform, domain, projectId) {
        let projects = this.state.projects;
        let project = projects.find(project => project.id === projectId);
        if (project) {
            project.metaData.disabled = false;
        }
        this.setState({
            projects
        })
    }

    removeProject(platform, domain, projectId) {
        let projects = this.state.projects.filter(project => project.id !== projectId);
        this.setState({
            projects: projects
        })
    }

    openInfoBox(anchorEl, issueKey, platform, domain, title, description, projectKey, projectId) {
        this.setState({
            showInfoBox: true,
            currentTaskData: {
                projectId: projectId,
                projectKey: projectKey,
                title: title,
                description: description,
                issueKey: issueKey,
                anchorEl: anchorEl,
                platform: platform,
                domain: domain
            }
        })
    }

    setSortingMode(sortingMode) {
        this.setState({
            sortingMode: sortingMode
        })
    }

    closeInfoBox() {
        this.setState({
            showInfoBox: false,
            currentTaskData: null,
        })
    }

    onDragEnd = result => {
        let project = this.state.projects.find(project => project.id === result.draggableId);
        if (project && result.source && result.destination) {
            let reorderedProjects = moveInArray(this.state.projects, result.source.index, result.destination.index)
            if (reorderedProjects && Array.isArray(reorderedProjects)) {
                this.setState({
                    projects: reorderedProjects
                })
            }
        }
    }

    showOrHideTimeTracker(showOrHide, issueData) {
        this.setState({
            showTimeTracker: showOrHide,
            trackedIssueData: {
                issueData: issueData
            }
        })
    }

    updateTimeTracker(timerData) {
        this.setState({
            trackedIssueData: {
                timerData: timerData,
                issueData: this.state.trackedIssueData.issueData
            }
        })
    }

    showErrorHintSnackbar(errorHintText) {
        this.setState({
            errorHintText
        })
    }
}

const mapStateToProps = (state) => {
    let resultTimeEstimates = state.accountReducer.timeEstimates;
    let changedTimeEstimates = state.userReducer.timeEstimates;

    if (changedTimeEstimates && changedTimeEstimates.length > 0) {
        resultTimeEstimates = changedTimeEstimates;
    }

    return {
        timeEstimates: resultTimeEstimates
    };
};

export default connect(
    mapStateToProps,
    null
)(DemoChain)
