import GooglePlayBadge from '../../images/google-play-badge.png'
import AppStoreBadge from '../../images/app-store-badge.svg'
import ChromeStoreBadge from '../../images/chrome-store-badge.png'
import {ComingSoonBadge} from "../commingsoon/ComingSoonBadge";
import {Link} from "react-router-dom";

export function SupportedPlatforms(props) {
    return (
        <div style={{
            width: "100%",
            boxShadow: "1px 1px 4px #00000033",
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            padding: "5px"
        }}>
            <a href="https://chromewebstore.google.com/detail/xlens/hdneojbalobldjlepokmfbeokjfegkkl" target="_blank" rel="noopener noreferrer">
                <img style={{
                    boxShadow: "1px 1px 2px #0000001A",
                    borderRadius: 10,
                    borderColor: "#0000000D",
                    borderWidth: 0.5,
                    borderStyle: "solid"
                }} height={67} src={ChromeStoreBadge} alt="Chrome WebStore"/>
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
                <img onClick={props.playStoreClick} height={100} src={GooglePlayBadge} alt="Google Play"/>
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
                {ComingSoonBadge(<img onClick={props.appStoreClick} style={{alignSelf: "center", opacity: 0.5}}
                                      height={67} src={AppStoreBadge} alt="App Store"/>)}
            </a>
        </div>
    )
}