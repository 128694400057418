import React from "react";
import {createLegalUrl} from "../LegalUtils";
import '../Legal.css';

export function CookiePolicy() {
    const policyId = 15055526;
    return (
        <div className='legalContentContainer'>
            <iframe name="legalContent" src={createLegalUrl(policyId, 'cookie')} style={{ minHeight: 700, minWidth: 700, overflow:"auto", width: '100%', height:'100%'}} frameBorder="0"/>
        </div>
    )
}




