import { Button } from "@material-ui/core";
import React from "react";
import "./StyledButton.css";

export const StyledButton = (props) => {

  return (
    <Button onClick={props.onClick} className="styledButton">
      {props.children}
    </Button>
  );
};
