import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {TaskchainConnector} from '../connectors/taskchain/TaskchainConnector'
import {JiraConnector} from '../connectors/jira/JiraConnector'

const prefix = 'tasks';

const jiraConnector = new JiraConnector();
const taskchainConnector = new TaskchainConnector();

export const fetchTasks = createAsyncThunk(`${prefix}/fetchTasks`, async (dataObject, thunkAPI) => {
    let tasks = jiraConnector.fetchTasks()
    return {
        tasks: tasks
    }
});

const taskSlice = createSlice({
    name: prefix,
    initialState: {
        tasks: []
    },
    extraReducers: {
        [fetchTasks.fulfilled]: (state, action) => {
            state.tasks = action.payload.tasks;
        },
        [fetchTasks.rejected]: (state, action) => {
            //todo handle
        }
    }
});

export const taskReducer = taskSlice.reducer;