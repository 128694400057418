import React from "react";
import {withStyles} from "@material-ui/core/styles";

import "./Users.css";
import "../../index.css";
import {Snackbar} from "@material-ui/core";
import {
    addProjects,
    addProjectsBulk, addProjectsToUser,
    disableProject,
    enableProject,
    removeProject, removeProjectFromUser,
} from "../../reducers/accountReducer";
import {fetchUsersForOrganisation, sendInvitation, updateAccessLevel} from "../../reducers/userReducer";
import {connect} from "react-redux";
import {generateAuthCodeUrl} from "../../connectors/jira/JiraConnector";
import {fetchTasks} from "../../reducers/taskReducer";
import {ImageAvatar} from "../../components/avatar/Avatar";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import NewAccountPlaceholder from "../../components/projectholder/NewAccountPlaceholder";
import Chip from "@material-ui/core/Chip";

import AccessTimeIcon from "@material-ui/icons/AccessTime";

import MultipleAccountProjectSelector
    , {SelectorType} from "../../components/multipleAccountsProjectsSelector/MultipleAccountsProjectsSelector";
import InviteButton from "../../components/button/InviteButton";
import {GeneralConnector} from "../../connectors/general/GeneralConnector";
import ChainLink from "../../components/chainlink/ChainLink";
import {AccessLevelEditor} from "../../components/accesslevel/AccessLevelEditor";

const Accordion = withStyles({
    root: {
        boxShadow: "none",
        background: "var(--bg-color)",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "var(--bg-color)",
        borderTop: "1px solid white",

        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.generalConnector = new GeneralConnector();

        this.state = {
            showInviteUser: false,
            newUserEmail: null,
            selectedId: null,
            menuIsOpen: false,
            userEmail: '',
            expandedAccordions: {},
            projectsData: null,
            additionalInvitations: [],
            selectedUser: null //the user, to which projects would be added.
        };
    }


    componentDidMount() {
        this.fetchUsers();
    }

    fetchUsers() {
        if (this.props.user) {
            try {
                this.props.fetchUsersForOrganisation(
                    this.props.user.userData.organisations[0]
                );
            } catch (e) {

            }
        }
    }

    // removeProject() {
    //   this.props.removeProject(this.props.projectId, this.props.connectionId);
    // }
    //
    // disableProject() {
    //   this.props.disableProject(this.props.projectId, this.props.connectionId);
    // }

    hideModals() {
        this.setState({
            shouldShowAddProjects: false,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.projectsData) { //TODO test
            this.fetchProjectsForCurrent();
        }
    }

    async fetchProjectsForCurrent() {
        for (let i in this.state.expandedAccordions && this.state.projectsData) {
            const userEmail = this.state.userEmail;
            const organisationId = this.props.user.userData.organisations[0];
            const projectsData = await this.generalConnector.fetchProjectData(userEmail, organisationId);
            const projects = []
            for (let item of projectsData) {
                projects.push(...item.projects);
            }

            const existingEntry = this.state.projectsData.find(data => data.userEmail === userEmail);
            if (existingEntry) {
                existingEntry.projects = projects;
            } else {
                const newEntry = {userEmail, projects}
                this.state.projectsData.push(newEntry);
            }

            this.setState({projectsData: [...this.state.projectsData]})
        }
    }

    addProjects(dataObject) {
        const user = this.props.users.find((user) => user.id === dataObject.userId);
        if (
            user &&
            user.projects &&
            Array.isArray(user.projects) &&
            dataObject.selectedProjects &&
            dataObject.selectedProjects.length > 0
        ) {
            dataObject.selectedProjects.forEach((project) => {
                if (!user.projects.includes(project.id)) {
                    user.projects.push(project.id);
                }
            });

            let users = this.props.users.find(
                (user) => user.id !== dataObject.userId
            );
            users.push(user);
            this.setState({users});
        }
    }

    showAddProjects(user) {
        this.setState({
            selectedUser: user,
        });
    }

    validateEmail(newUserEmail) {
        const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (res.test(String(newUserEmail).toLowerCase())) {
            this.setState({newUserEmail});
        } else {
            this.setState({newUserEmail: null});
        }
    }

    getProjectsOrPlaceholder(userEmail) {
        if (!this.state.projectsData) {
            return []
        }
        const entry = this.state.projectsData.find(data => data.userEmail === userEmail);
        return entry ? entry.projects : [];
    }


    selectProject(id) {
        if (id === this.state.selectedProjectId) {
            this.setState({
                selectedProjectId: null,
            });
        } else {
            this.setState({
                selectedProjectId: id,
            });
        }
    }

    disableProject(projectId, connectionId) {
        if (this.props.user) {
            let organisationId = this.props.user.userData.organisations[0]
            let userEmail = this.props.user.userEmail;

            this.props.disableProject({
                organisationId,
                userEmail,
                connectionId,
                projectId
            })
        }
    }

    enableProject(projectId, connectionId) {
        if (this.props.user) {
            let organisationId = this.props.user.userData.organisations[0]
            let userEmail = this.props.user.userEmail;

            this.props.enableProject({
                organisationId,
                userEmail,
                connectionId,
                projectId
            })
        }
    }

    removeProject(projectId, connectionId) {
        if (this.props.user) {
            let organisationId = this.props.user.userData.organisations[0]
            let userEmail = this.props.user.userEmail;

            this.props.removeProject({
                organisationId,
                userEmail,
                connectionId,
                projectId
            })
        }
    }

    archiveProject(projectId) {
        this.props.archiveProject({
            username: this.props.user.userEmail,
            projectId: projectId
        })
    }

    render() {
        return (
            <>
                {this.props.user && this.props.user.userData.accessLevel === "admin" ? <div className="container">
                    <div className="header-users-container">
                        <p style={{color: "#3160e1", margin: '0px', textAlign: 'center'}}>
                            Team members
                        </p>

                        <InviteButton
                            sendInvitation={async (newUserEmail, accessLevel) => {
                                const invitationData = await this.props.sendInvitation({
                                    receiverEmail: newUserEmail,
                                    organisationId: this.props.user.userData.organisations[0],
                                    senderEmail: this.props.user.userEmail,
                                    accessLevel: accessLevel
                                    // newUserColor: generateRandomColor(),
                                });

                                if (invitationData && invitationData.payload) {
                                    const invitation = invitationData.payload.invitationData.invitation;
                                    const newInvitations = [...this.state.additionalInvitations]
                                    newInvitations.push(invitation);
                                    this.setState({
                                        additionalInvitations: newInvitations
                                    })
                                }
                            }}
                        />
                    </div>

                    {this.state.showMessage ? (
                        <Snackbar
                            open={true}
                            autoHideDuration={6000}
                            onClose={() => {
                                this.setState({
                                    showMessage: null,
                                });
                            }}
                            message={this.state.showMessage}
                        />
                    ) : null}
                    {this.state.selectedUser ? (
                        <MultipleAccountProjectSelector
                            onClose={() => {
                                this.setState({
                                    selectedUser: null
                                })
                            }}
                            addProjectsToUser={async (userProjectsData) => {
                                await this.props.addProjectsToUser(userProjectsData)
                                this.setState({
                                    selectedUser: null
                                })
                                this.fetchUsers()
                            }}
                            selectorType={SelectorType.AddExistingProjects}
                            user={this.state.selectedUser}
                            orderedProjectsData={[
                                {
                                    connectionId: "123",
                                    platform: "jira",
                                    projects: this.props.projects,
                                },
                            ]}
                        />
                    ) : null}
                    {this.props.users.map((user) => (
                        <ul style={{paddingLeft: '20px', paddingRight: '20px', margin: '0px'}}>
                            <Accordion
                                key={user.email}
                                onClick={(event) => {
                                    const userEmail = user.email;

                                    const isExpanded = !!this.state.expandedAccordions[userEmail];
                                    this.setState(prevState => ({
                                        ...prevState,
                                        userEmail: isExpanded ? '' : userEmail,
                                        expandedAccordions: {
                                            ...prevState.expandedAccordions,
                                            [userEmail]: !isExpanded
                                        },
                                    }));
                                }}
                                expanded={!!this.state.expandedAccordions[user.email]}
                            >
                                <AccordionSummary key={`summary-${user._id}`}>
                                    <div className="memberContainer">
                                        <ImageAvatar src={user.imageUrl} alt=""/>
                                        <h3 className="memberName">{user.name}</h3>
                                        <AccessLevelEditor onClick={(event) => event.stopPropagation()}
                                                           selectedAccessLevel={user.accessLevel}
                                                           setSelectedAccessLevel={(newAccessLevel) => {
                                                               this.props.updateAccessLevel({
                                                                   userEmail: user.email,
                                                                   newAccessLevel
                                                               })
                                                           }}/>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails style={{color: "white", overflowX: 'auto'}}>
                                    <NewAccountPlaceholder user={user} onClick={(event) => event.stopPropagation()}
                                                           showAddProjects={this.showAddProjects.bind(this)}/>
                                    {this.props.projects.filter(project => {
                                        //TODO refactor
                                        if (user.accessLevel === "admin") {
                                            return true
                                        }
                                        const visibleProjects = user.visibleProjects && user.visibleProjects[this.props.organisationId] ? user.visibleProjects[this.props.organisationId] : [];
                                        return visibleProjects.includes(project.id);
                                    }).map(project => {
                                        return <div className='chainLinkContainer'
                                                    onClick={(event) => event.stopPropagation()}>
                                            <ChainLink
                                                disableAdminMode
                                                removeProjectFromUser={async (dataObject) => {
                                                    await this.props.removeProjectFromUser(dataObject)
                                                    this.fetchUsers();
                                                }
                                                }
                                                mainUser={this.props.user}
                                                user={user}
                                                displayName={project.displayName}
                                                projectKey={project.key}
                                                projectId={project.id}
                                                archiveProject={this.archiveProject.bind(this)}
                                                showAddProjects={this.showAddProjects.bind(this)}
                                                selected={this.state.selectedProjectId ? project.id === this.state.selectedProjectId : true}
                                                connectionId={project.metaData.connectionId}
                                                domain={project.metaData.domain}
                                                platform={project.metaData.platform}
                                                enableProject={this.enableProject.bind(this)}
                                                disableProject={this.disableProject.bind(this)}
                                                removeProject={this.removeProject.bind(this)}
                                                selectProject={this.selectProject.bind(this)}
                                                projectMeta={project.metaData}
                                            />
                                        </div>
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        </ul>

                    ))}

                    <ul style={{paddingLeft: '20px', paddingRight: '20px', margin: '0px'}}>
                        {this.props.openInvitations.map((invitation, index) => <Invitation
                            imageUrl={invitation.imageUrl} receiverEmail={invitation.receiverEmail}/>)}
                        {this.state.additionalInvitations.map((invitation, index) => <Invitation
                            imageUrl={invitation.imageUrl} receiverEmail={invitation.receiverEmail}/>)}
                    </ul>
                </div> : null}
            </>
        );
    }
}

const Invitation = (props) => {
    return (<Accordion expanded={false}>
        <AccordionSummary>
            <div className="memberContainer">
                <ImageAvatar src={props.imageUrl} alt=""/>

                <h3 className="memberName">{props.receiverEmail}</h3>

                <Chip
                    className="chip"
                    icon={<AccessTimeIcon/>}
                    label="pending"
                    size="small"
                />
            </div>
        </AccordionSummary>
    </Accordion>)
}
const mapDispatchToProps = (dispatch) => {
    return {
        addProjectsToUser: (dataObject) => dispatch(addProjectsToUser(dataObject)),
        removeProjectFromUser: (dataObject) => dispatch(removeProjectFromUser(dataObject)),
        obtainAuthCode: (dataObject) => window.location.replace(generateAuthCodeUrl(dataObject)),
        fetchTasks: (dataObject) => dispatch(fetchTasks(dataObject)),
        disableProject: (dataObject) => dispatch(disableProject(dataObject)),
        enableProject: (dataObject) => dispatch(enableProject(dataObject)),
        removeProject: (dataObject) => dispatch(removeProject(dataObject)),
        addProjects: (dataObject) => dispatch(addProjects(dataObject)),
        addProjectsBulk: (dataObject) => dispatch(addProjectsBulk(dataObject)),
        sendInvitation: (dataObject) => dispatch(sendInvitation(dataObject)),
        updateAccessLevel: (dataObject) => dispatch(updateAccessLevel(dataObject)),
        fetchUsersForOrganisation: (organisationId) => dispatch(fetchUsersForOrganisation(organisationId)),
    };
};

const mapStateToProps = (state, ownProps) => {
    //TODO this is only handling ONE organisation.
    let organisationId = ownProps.user ? ownProps.user.userData.organisations[0] : null;
    let users = organisationId ? state.userReducer.users.filter((singleUser) => singleUser.organisations.includes(organisationId) && ownProps.user.userEmail !== singleUser.email) : [];

    console.log("USERS ARRAY: ", users);

    return {
        users,
        organisationId, //TODO handle multiple organisations
        openInvitations: state.userReducer.openInvitations,
        projects: state.accountReducer.projects
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);