import React from 'react'
import './DemoDivider.css'
import {Text} from "../../../../components/text/Text";

export default class DemoDivider extends React.Component {

    constructor() {
        super();

        this.state = {
            showPricing: false
        }
    }

    render() {
        return (
            <div className='demoDividerContainer'>
                <div className='demoDividerLine'/>
                <Text opacity={0.3} marginLeft={10} marginRight={10} light>interactive demo</Text>
                <div className='demoDividerLine'/>
            </div>
        );
    }

}