import React from 'react';
import './BadgeWrapper.css';

const BadgeWrapper = ({text, children}) => {
    return (
        <div className="card" data-label={text}>
            <div className="card__container">
                {children}
            </div>
        </div>
    );
};

export default BadgeWrapper;
