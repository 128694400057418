import {Text} from "../text/Text";
import React from "react";
import Cloud from '@material-ui/icons/Cloud';
import Sort from '@material-ui/icons/Sort';
import Timeline from '@material-ui/icons/Timeline';
import ImportExport from '@material-ui/icons/ImportExport';
import Info from '@material-ui/icons/Info';
import TimeLapse from '@material-ui/icons/Timelapse';
import {HeadlineText} from "../headlinetext/HeadlineText";
import {SecondaryText} from "../secondarytext/SecondaryText";

const containerStyle = {
    width:'100% ',
    height: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center'
}

const gridContainer = {
    alignSelf: 'center',
    width:'80%',
    paddingLeft: 100,
    display: 'grid',
    marginTop: 80,
    alignItems: 'center',
    gridTemplateColumns: '30% 30% 30%',
}

const gridContainerTablet = {
    display: 'grid',
    marginTop: 80,
    alignItems: 'center',
    gridTemplateColumns: '50% 50%',
}

const gridContainerMobile = {
    display: 'grid',
    marginTop: 80,
    marginBottom: 1000,
    alignItems: 'center',
    gridTemplateColumns: '100%',
}

const gridChildItem = {
    marginBottom: 20,
    marginRight: 20,
    marginTop: 20,
    paddingTop: 20,
    borderRadius: 5,
    height: 200,
    borderColor:'white',
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    padding: 10,
    textAlign:'center',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center'
}

const gridItemIconsStyle = {
    color: 'white',
    width: 70,
    height: 70,
    marginTop: 20,
    alignSelf:'center'
}

export default class Benefits extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            width: screen.width,
        }
    }

    updateDimensions = () => {
        this.setState({width: screen.width});
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        let gridContainerStyle = gridContainer;
        if (this.state.width < 450) {
            gridContainerStyle = gridContainerMobile;
        }else if (this.state.width < 850) {
            gridContainerStyle = gridContainerTablet;
        }

        return (
            <div style={containerStyle}>
                <HeadlineText>Built for software developers</HeadlineText>
                <SecondaryText>By automating the task prioritisation and communication tracking,
                    taskchain helps developers, who work on multiple projects and platforms, to really concentrate on their craft. It saves time by emphasizing simplicity and integrability.
                    Pick a platform, add projects and let taskchain sort and monitor the rest.</SecondaryText>
                <div style={gridContainerStyle}>
                    <div onMouseOver="this.style.borderWidth=3"
                         onMouseOut="this.style.borderWidth=1"
                         style={gridChildItem}>
                        <Text width={'80%'} light>Connect projects from multiple platforms</Text>
                        <Cloud style={gridItemIconsStyle}/>
                    </div>

                    <div onMouseOver="this.style.borderWidth=3"
                         onMouseOut="this.style.borderWidth=1"
                         style={gridChildItem}>
                        <Text width={'80%'} light> Automatically sort tasks by project or importance</Text>
                        <Sort style={gridItemIconsStyle}/>
                    </div>

                    <div onMouseOver="this.style.borderWidth=3"
                         onMouseOut="this.style.borderWidth=1"
                         style={gridChildItem}>
                        <Text width={'80%'} light>Always know when you'll be done with a group of tasks</Text>
                        <Timeline style={gridItemIconsStyle}/>
                    </div>

                    <div onMouseOver="this.style.borderWidth=3"
                         onMouseOut="this.style.borderWidth=1"
                         style={gridChildItem}>
                        <Text width={'80%'} light> Don't worry about manually importing data</Text>
                        <ImportExport style={gridItemIconsStyle}/>
                    </div>

                    <div onMouseOver="this.style.borderWidth=3"
                         onMouseOut="this.style.borderWidth=1"
                         style={gridChildItem}>
                        <Text width={'80%'} light> Stay informed without switching tabs</Text>
                        <Info style={gridItemIconsStyle}/>
                    </div>

                    <div onMouseOver="this.style.borderWidth=3"
                         onMouseOut="this.style.borderWidth=1"
                         style={gridChildItem}>
                        <Text width={'80%'} light> Coupled estimates and time trackings</Text>
                        <TimeLapse style={gridItemIconsStyle}/>
                    </div>
                </div>
            </div>
        );
    }
}