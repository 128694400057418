import Select from "@material-ui/core/Select";
import React, {useState} from "react";

export const AccessLevelEditor = (props) => {
    const [accessLevel, setAccessLevel] = useState(props.selectedAccessLevel)

    return <div onClick={props.onClick}>
        <Select
            native
            className='access-level-dropdown'
            value={accessLevel}
            onChange={(event) => {
                setAccessLevel(event.target.value)
                props.setSelectedAccessLevel(event.target.value)
            }}
            inputProps={{
                name: 'status',
                id: 'outlined-age-native-simple',
            }}>
            <option value="user">User</option>
            <option value="admin">Admin</option>
        </Select>
    </div>
}