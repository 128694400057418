import {ENTRY_FUNCTION_URL, BASE_URL} from "../../Constants";

export class GeneralConnector {

    async createUser(dataObject) {
        let requestData = {
            name: dataObject.name,
            email: dataObject.email,
            accessLevel: 'admin',
            invitationId: dataObject.invitationId
        }

        let response = await fetch(`${BASE_URL}/users/create-user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let body = await response.json();
        if (body) {
            return body.data
        }
        return null;
    }

    async fetchUser(userEmail) {
        let requestData = {
            userEmail: userEmail
        }

        let response = await fetch(`${BASE_URL}/users/fetch-user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let body = await response.json();
        console.log(`fetchUser ${JSON.stringify(body)}`);
        if (body) {
            return body.data
        }
        return null;
    }

    async fetchProjectData(email, organisationId) {
        let data = {
            userEmail: email,
            organisationId: organisationId
        }

        let response = await fetch(`${BASE_URL}/all/fetch-projects`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        let projectsData = {}
        if (response) {
            let body = await response.json();
            if (body) {
                projectsData = body.data
            }
        }
        return projectsData
    }

    async fetchDisconnectedProjectsData(user) {
        if (user) {
            let data = {
                userEmail: user.userEmail,
                organisationId: user.userData.organisations[0]
            };

            let response = await fetch(`${BASE_URL}/all/fetch-disconnected-projects`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            let projectsData = {}
            if (response) {
                let body = await response.json();

                if (body) {
                    projectsData = body.data
                }
            }

            return projectsData
        }
    }

    async addProjectsBulk(dataObject) {
        let data = {
            userEmail: dataObject.user.userEmail,
            organisationId: dataObject.user.userData.organisations[0],
            projectsData: dataObject.selectedProjects
        };

        let response = await fetch(`${BASE_URL}/all/add-projects-bulk`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        let projectsData = {}
        if (response) {
            let body = await response.json();

            if (body) {
                projectsData = body.data
            }
        }

        return projectsData
    }

    async fetchSingleUser(dataObject) {
        let requestData = {
            username: dataObject.username,
            platform: 'all',
            event: {
                type: 'fetch_user',
                data: {
                    username: dataObject.searchedUserEmail
                }
            }
        }

        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let data = await response.json();
        let body = JSON.parse(data.body)
        if (body) {
            return body.data
        }
        return null;
    }

    async fetchBlogs(dataObject) {
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                platform: 'all',
                username: dataObject.username,
                event: {
                    data: {
                        filterByDate: dataObject.filterDate,
                    },
                    type: 'fetch_blogs'
                }
            })
        });

        let blogData = []
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body)
            if (body) {
                blogData = body.data
            }
        }

        return blogData
    }

    async sendInvitation(dataObject) {
        let response = await fetch(`${BASE_URL}/users/send-invitation`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataObject)
        });

        let body = await response.json();
        if (body) {
            return body.data
        }
        return null;
    }

    async fetchInvitationStatus(dataObject) {
        let requestData = {
            invitationId: dataObject.invitationId,
        }

        let response = await fetch(`${BASE_URL}/users/fetch-invitation-status`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let body = await response.json();
        if (body) {
            return body.data
        }
        return null;
    }

    async acceptInvitation(dataObject) {
        const requestData = {
            invitationId: dataObject.invitationId,
            organisationId: dataObject.organisationId
        }

        let response = await fetch(`${BASE_URL}/users/accept-invitation`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let body = await response.json();
        if (body) {
            return body.data
        }

        return null;
    }

    async disableProject(dataObject) {
        let requestData = {
            projectId: dataObject.projectId,
            userEmail: dataObject.userEmail,
            connectionId: dataObject.connectionId,
            organisationId: dataObject.organisationId,
        }

        let response = await fetch(`${BASE_URL}/all/disable-project`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let body = await response.json();
        if (body) {
            return body.data.disabledProjectId
        }
        return null;
    }

    async enableProject(dataObject) {
        let requestData = {
            projectId: dataObject.projectId,
            userEmail: dataObject.userEmail,
            connectionId: dataObject.connectionId,
            organisationId: dataObject.organisationId,
        }

        let response = await fetch(`${BASE_URL}/all/enable-project`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let body = await response.json();
        if (body) {
            return body.data.enabledProjectId
        }
        return null;
    }

    async removeProject(dataObject) {
        let requestData = {
            projectId: dataObject.projectId,
            userEmail: dataObject.userEmail,
            connectionId: dataObject.connectionId,
            organisationId: dataObject.organisationId,
        }

        let response = await fetch(`${BASE_URL}/all/remove-project`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let body = await response.json();
        if (body) {
            return body.data.removedProjectId
        }
        return null;
    }

    async fetchUsersForOrganisation(organisationId) {
        let response = await fetch(`${BASE_URL}/users/${organisationId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        let body = await response.json();

        if (body) {
            return body.data
        }
        return null;
    }

    async fetchComments(userEmail, organisationId, connectionId, originalProjectId, issueKey) {
        let requestData = {
            userEmail,
            connectionId,
            organisationId,
            originalProjectId,
            issueKey
        }

        let comments = [];

        let response = await fetch(`${BASE_URL}/all/fetch-comments`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let body = await response.json();

        if (body) {
            comments = body.data
        }

        return comments;
    }

    async postComment(userEmail, organisationId, connectionId, originalProjectId, issueKey, text) {

        let requestData = {
            userEmail,
            connectionId,
            organisationId,
            originalProjectId,
            issueKey,
            text
        }

        let response = await fetch(`${BASE_URL}/all/post-comment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let body = await response.json();

        if (body) {
            return body.data
        }

        return null;
    }

    async updateAccessLevel(userEmail, newAccessLevel) {
        let requestData = {
            userEmail,
            newAccessLevel
        }

        let response = await fetch(`${BASE_URL}/users/update-access-level`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let body = await response.json();

        if (body) {
            return body.data
        }

        return null;
    }

    async addProjectsToUser(userProjectsData) {
        let requestData = {
            userEmail: userProjectsData.user.email,
            organisationId: userProjectsData.user.organisations[0],
            selectedProjects: userProjectsData.selectedProjects.map(item => item.projectId)
        }

        let response = await fetch(`${BASE_URL}/users/add-projects-to-user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let body = await response.json();

        if (body) {
            return body.data
        }

        return null;
    }

    async removeProjectFromUser(userProjectsData) {
        let requestData = {
            userEmail: userProjectsData.email,
            organisationId: userProjectsData.organisationId,
            projectId: userProjectsData.projectId
        }

        let response = await fetch(`${BASE_URL}/users/remove-project-from-user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });

        let body = await response.json();

        if (body) {
            return body.data
        }

        return null;
    }

    async fetchBlogposts(fromDate, toDate) {
        let response = await fetch(`${BASE_URL}/blog/fetch-blogposts?${fromDate ? `fromDate=${fromDate}`: ''}${toDate ? `&toDate=${toDate}`: ''}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });

        let body = await response.json();

        if (body) {
            return body.data
        }

        return null;
    }
    async fetchSingleBlogpost(blogpostId) {
        let response = await fetch(`${BASE_URL}/blog/fetch-single-blogpost/${blogpostId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });

        let body = await response.json();

        if (body) {
            return body.data
        }

        return null;
    }

}
