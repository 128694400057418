import {ENTRY_FUNCTION_URL} from "../../Constants";
import {generateRandomColor} from "../../util/utils";
import {generateProjectKey} from "../../util/taskchainUtils";

export class TaskchainConnector {

    async createProject(event) {
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: event.username,
                platform: 'taskchain',
                event: {
                    type: 'create_project',
                    data: {
                        projectColor: generateRandomColor(),
                        projectKey: generateProjectKey(event.data.projectName),
                        projectName: event.data.projectName
                    }
                }
            })
        });

        let newProject = null
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body)
            if (body) {
                newProject = body.data.newProject;
            }
        }
        return newProject
    }

    async removeProject(event) {
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: event.username,
                platform: 'taskchain',
                event: {
                    type: 'remove_project',
                    data: {
                        projectId: event.projectId
                    }
                }
            })
        });

        let projectId = null
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body)
            if (body) {
                projectId = body.data.projectId;
            }
        }
        return projectId
    }

    async disableProject(event) {
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: event.username,
                platform: 'taskchain',
                event: {
                    type: 'disable_project',
                    data: {
                        projectId: event.projectId
                    }
                }
            })
        });

        let projectId = null
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body)
            if (body) {
                projectId = body.data.disabledProjectId;
            }
        }
        return projectId
    }

    async enableProject(event) {
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: event.username,
                platform: 'taskchain',
                event: {
                    type: 'enable_project',
                    data: {
                        projectId: event.projectId
                    }
                }
            })
        });


        let projectId = null
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body)
            if (body) {
                projectId = body.data.enabledProjectId;
            }
        }
        return projectId
    }

    async archiveProject(event) {
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: event.username,
                platform: 'taskchain',
                event: {
                    type: 'archive_project',
                    data: {
                        projectId: event.projectId
                    }
                }
            })
        });

        let projectId = null
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body)
            if (body) {
                projectId = body.data.archivedProjectId;
            }
        }
        return projectId
    }

    async createTask(dataObject) {
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: dataObject.username,
                platform: 'taskchain',
                event: {
                    type: 'create_task',
                    data: {
                        projectId: dataObject.projectId,
                        title: dataObject.title,
                        description: dataObject.description,
                        priority: dataObject.priority
                    }
                }
            })
        });

        let newTask = null
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body)
            if (body) {
                newTask = body.data.newTask;
            }
        }
        return newTask
    }

    async editTask(dataObject) {
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: dataObject.username,
                platform: 'taskchain',
                event: {
                    type: 'edit_task',
                    data: {
                        projectId: dataObject.projectId,
                        issueKey: dataObject.issueKey,
                        newTaskData: {
                            title: dataObject.newTaskData.title,
                            description: dataObject.newTaskData.description,
                            priority: dataObject.newTaskData.priority
                        }
                    }
                }
            })
        });

        let editedTask = null
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body)
            if (body) {
                editedTask = body.data.editedTask;
            }
        }
        return editedTask
    }

    async removeTask(dataObject) {
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: dataObject.username,
                platform: 'taskchain',
                event: {
                    type: 'remove_task',
                    data: {
                        projectId: dataObject.projectId,
                        issueKey: dataObject.issueKey
                    }
                }
            })
        });

        let removedTaskId = null
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body)
            if (body) {
                removedTaskId = body.data.removedTaskId;
            }
        }
        return removedTaskId
    }

    async fetchArchivedProjects(email) {
        let response = await fetch(ENTRY_FUNCTION_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: email,
                platform: 'taskchain',
                event: {
                    type: 'fetch_archived_projects'
                }
            })
        });

        let archivedProjects = []
        if (response) {
            let data = await response.json();
            let body = JSON.parse(data.body)
            if (body) {
                archivedProjects = body.data.archivedProjects;
            }
        }
        return archivedProjects
    }


}